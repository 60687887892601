import { Injectable } from '@angular/core';
import { Sponsor } from 'src/app/models/sponsor/sponsor';
import { ContentfulService } from 'src/app/modules/core/services/contentful/contentful.service';

@Injectable({
  providedIn: 'root'
})

export class SponsorService {

  constructor(private contentful: ContentfulService) { }

  getSponsors(): Promise<Sponsor[]>{
    return this.contentful.getContentCollection('sponsor').then(
      sponsors => sponsors.map(sponsor => {
        const fields = sponsor.fields;

        return {
          id: sponsor.sys.id,
          company: fields.company,
          description: fields.string,
          address: fields.address,
          phone: fields.phone,
          website: fields.website,
          image: {
            title: fields.image.fields.file.name,
            url: fields.image.fields.file.url
          }
        } as Sponsor;
      })
    )
  }
}
