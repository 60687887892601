import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormAction } from '../../models/form-action/form-action';
import { Record } from '../../models/record';
import { TableColumn } from '../../models/table-column/table-column';
import { FirebaseManager } from '../../services/firebase/firebase-manager.service';
import { APP_DATE_FORMATS } from '../../util/datepicker-format';
import { TableCondition } from '../table/table.component';

const OPERATORS = {
  string: [
    { value: 'LIKE', label: 'contient' },
    { value: '=', label: 'égale à' },
    { value: '!=', label: 'différent de' }
  ],
  reference: [
    { value: 'LIKE', label: 'contient' },
    { value: '=', label: 'égale à' },
    { value: '!=', label: 'différent de' }
  ],
  date: [
    { value: '<', label: 'avant le' },
    { value: '>', label: 'après le' },
    { value: '=', label: 'le' },
  ]
}

class TableFilter {
  expanded: boolean;
  fields: any;
  conditions: any;

  constructor() {
    this.fields = [
      {
        name: 'sys_created_on', label: 'Date de création', type: 'date', operators: [
          { value: '<', label: 'avant le' },
          { value: '>', label: 'après le' },
          { value: '=', label: 'le' },
        ]
      },
      {
        name: 'sys_updated_on', label: 'Date de mise à jour', type: 'date', operators: [
          { value: '<', label: 'avant le' },
          { value: '>', label: 'après le' },
          { value: '=', label: 'le' },
        ]
      }
    ];

    this.conditions = [
      { field: null, operator: null, value: null }
    ];
  }
}

@Component({
  selector: 'app-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})

export class ListCardComponent implements OnInit, OnChanges {
  @Input() table: string;
  @Input() title: string;
  @Input() columns: TableColumn[];
  @Input() conditions: TableCondition[];
  @Input() actions: FormAction[];
  @Input() page: string;
  @Input() loading: boolean;
  @Input() sort: { field: string, direction: 'asc' | 'desc' };
  @Input() redirectTo: string;

  records: Record[];
  _records: Record[];
  keywords: string;
  filter = new TableFilter();

  _conditions: TableCondition[];

  constructor(private router: Router, private location: ActivatedRoute, private firebase: FirebaseManager) { }

  ngOnInit(): void {
    this.redirectTo = this.router.url;

    this.location.queryParams.subscribe(params => {
      if (params.sysparm_title) {
        this.title = params.sysparm_title;
      }


      // this.parameters = [];

      // if (params['sysparm_query']) {
      //   const filter = params['sysparm_query'];

      //   filter.split('^').forEach(condition => {
      //     const field = condition.split('=')[0];
      //     const value = condition.split('=')[1];

      //     if (value) {
      //       this.parameters.push({ field: field, value: value });
      //     }
      //   })
      // }

      if (params['sysparm_sort']) {
        const sortBy = params['sysparm_sort'];
        const sortDir = params['sysparm_sort_dir'] || 'asc';

        this.records = this.records.sort((current, next) => {
          if (!current.data[sortBy] || !next.data[sortBy]) {
            console.warn('Field not defined');
            return;
          }

          return this.compare(current.data[sortBy], next.data[sortBy], sortDir == 'asc');
        })

        this.applyFilters();
      }
    })
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change['columns']) {
      this.filter = new TableFilter();

      this.columns?.forEach(column => {
        const field = this.filter.fields.find(field => column.name == field.name);

        if (!field) {
          const operators = OPERATORS[column.type];

          if (operators) {
            this.filter.fields.push(
              { name: column.name, label: column.label, type: column.type, operators: operators }
            )
          }
        }
      })
    }

    if (change['table']) {
      this.loading = true;
      this.firebase.getCollection(this.table).then(
        records => {
          this.records = records;
          this.clearFilter();
          this.loading = false;
        }
      )
    }

    if (!this.sort) {
      this.sort = { field: 'udpated_on', direction: 'desc' }
    }
  }

  sortBy(field: TableColumn): void {
    if (this.sort?.field === field.name) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field.name;
      this.sort.direction = 'asc';
    }

    this.router.navigate([`/admin/${this.table}`], {
      queryParams: {
        sysparm_sort: this.sort.field,
        sysparm_sort_dir: this.sort.direction
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  applyFilters(): void {
    this._conditions = this.filter.conditions;

    if (!this._conditions) {
      return;
    }

    this._records = this.records?.filter(record => {
      return this._conditions.reduce((match: boolean, condition) => {
        if (!condition.field) {
          return true;
        }

        if (record[condition.field.name] === undefined) {
          return match;
        }

        if (condition.field.type === 'date') {
          const date = new Date(condition.value);

          if (condition.operator.value === '<') {
            match = new Date(record[condition.field.name]) < date;
          }

          if (condition.operator.value === '>') {
            match = new Date(record[condition.field.name]) > date;
          }

          if (condition.operator.value === '=') {
            match = new Date(record[condition.field.name]) === date;
          }
        }

        return match
      }, true)
    })
  }

  clearFilter() {
    this.filter.conditions = [{ field: null, operator: null, value: null }];
    this.applyFilters();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.keywords = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    this._conditions = null;
    this.clearFilter();
  }
}
