import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { FootballTeam } from 'src/app/interfaces/football-team.interface';
import { ChampionshipManager } from 'src/app/services/championship/championship-manager.service';

@Component({
  selector: 'app-championship-ranking',
  templateUrl: './championship-ranking.component.html',
  styleUrls: ['./championship-ranking.component.scss']
})
export class ChampionshipRankingComponent implements OnInit {
  teams: FootballTeam[];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  
  constructor(private championshipManager: ChampionshipManager, private location: ActivatedRoute, private breakpointObserver: BreakpointObserver,) { }

  ngOnInit() {
    this.location.queryParams.subscribe(params => {
      const division = params['championship'];
      const season = params['season'];
      const day = params['day'];

      if(!division || !season || !day){
        return [];
      }

      this.championshipManager.getRanking(division, season, day).then(
        teams => this.teams = teams
      ).catch(error => alert(error));
    })
  }
}
