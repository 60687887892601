export const environment = {
  production: false,
  baseUrl: 'https://challenge-celtic.fr',
  firebase: {
    apiKey: "AIzaSyCdKjkwWsEfEESgW3f6GjwDP_KEUiLXjAE",
    authDomain: "challenge-celtic.firebaseapp.com",
    databaseURL: "https://challenge-celtic.firebaseio.com",
    projectId: "challenge-celtic",
    storageBucket: "challenge-celtic.appspot.com",
    messagingSenderId: "263848018027",
    appId: "1:263848018027:web:82b5faa09e9baf6ceef12e",
    measurementId: "G-09RBGFXX43"
  },
  contenful: {
    space: 'n6kcz6twc219',
    accessToken: '92wfui-AXBlU-Vj6f1nLaS65XGatW8Sa054zHIjUcWg',
    contentTypeIds: {
      club: 'club',
      championship: 'championship',
      championshipDay: 'championship-day',
      season: 'season',
      player: 'player',
      page: 'page-content'
    }
  },
  tiny: {
    apiKey: '9b51mnxiup7cvsdch6x7a8i6ss8jm4xeqg707pxoke2cs2lp',
    inline: true,
    config: {
      height: 400,
      entity_encoding: 'raw',
      toolbar: 'bold italic | numlist bullist',
      plugins: [
        'advlist autolink lists link image charmap print preview',
        'searchreplace visualblocks code fullscreen',
        'table paste help wordcount'
      ]
    }
  }
};
