<mat-card>
    <mat-card-header>
        Pièces jointes
    </mat-card-header>
    <mat-card-content>
        <!-- <nb-alert status="danger" *ngIf="message" closable (close)="message = null">{{ message }}</nb-alert> -->
        <span *ngIf="attachments.length == 0">Aucune pièce jointe</span>
        <ul class="list-unstyled">
            <li *ngFor="let attachment of attachments">
                <div class="attachment-box">
                    <a href="{{ attachment.file }}" target="_blank" *ngIf="!edit">
                        {{ attachment.name }}
                    </a>
                    <div>
                        <a href="{{ attachment.file }}" class="btn-download" target="_blank" *ngIf="!edit"
                            download="{{attachment.name}}">
                            <mat-icon>get_app</mat-icon>
                        </a>
                        <span class="btn-edit" (click)="edit = attachment" *ngIf="!edit">
                            <mat-icon>edit</mat-icon>
                        </span>
                        <span class="btn-delete" (click)="deleteFile(attachment.id)" *ngIf="!edit">
                            <mat-icon>delete_outline</mat-icon>
                        </span>
                    </div>
                </div>
                <form *ngIf="edit == attachment" #form="ngForm">
                    <mat-form-field>
                        <input matInput name="name" [(ngModel)]="attachment.name"
                            (keydown.enter)="onRename(attachment)">
                        <mat-hint>Taper "Entrer" pour sauvegarder</mat-hint>
                    </mat-form-field>
                </form>
            </li>
        </ul>
    </mat-card-content>
    <mat-card-footer>
        <div class="text-center">
            <input type="file" (change)="onDrop($event)" #hiddenfileinput style="display: none" multiple accept=".pdf,.png,.jpg,.jpeg">
            <button nbButton status="primary" [disabled]="edit"
                (click)="hiddenfileinput.click()">{{ uploading ? "Chargement..." : "Sélectionner un fichier"}}</button>
        </div>
    </mat-card-footer>
</mat-card>