<div [hidden]="loading">
    <div *ngIf="conditions">
        <div *ngFor="let condition of conditions">
            <div *ngIf="condition.field && condition.label && condition.value">
                {{ condition.field.label }}&nbsp;{{ condition.operator.label}}&nbsp;{{ condition.value}}
            </div>
        </div>
    </div>
    <div *ngIf="rows?.data.length == 0" class="text-center">
        <br />
        <h5 class="text-muted">Aucune donnée ne correspond à votre recherche...</h5>
    </div>
    <div class="mat-elevation-z8" [hidden]="rows?.data.length == 0">
        <table mat-table [dataSource]="rows" class="mat-elevation-z8" matSort>
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef class="text-center">
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [attr.aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" [attr.aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container [matColumnDef]="column.name" *ngFor="let column of columns;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="column.class">{{ column.label }}</th>
                <td mat-cell *matCellDef="let element" [ngClass]="column.class" (click)="onRowClick(element)">
                    <span [ngSwitch]="column.type">
                        <span *ngSwitchCase="'choice'">
                            <span>{{ element[column.name] || '-' }}</span>
                            <!-- <span *ngIf="column?.choices?.length > 0 && canEdit">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label=""
                                    class="list-edit-button">
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="save(element.id, column.name, null)"
                                        [hidden]="!element.data[column.name]">
                                        <i>-- Aucun --</i>
                                    </button>
                                    <button mat-menu-item *ngFor="let option of column.choices"
                                        (click)="save(element.id, column.name, option)">
                                        <span>{{ option.label }}</span>
                                    </button>
                                </mat-menu>
                            </span> -->
                        </span>
                        <span *ngSwitchCase="'reference'">
                            <span>{{ element[column.name] || "-" }}</span>
                            <!-- <a [routerLink]="['/' + column.page, reference]" mat-button
                                *ngIf="column.page && reference" matSuffix mat-icon-button
                                aria-label="Détails">
                                <mat-icon>launch</mat-icon>
                            </a> -->
                            <!-- <button mat-icon-button [matMenuTriggerFor]="refMenu" aria-label="Selectionner une valeur"
                                *ngIf="column?.choices.length > 0 && canEdit" class="list-edit-button">
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </button>
                            <mat-menu #refMenu="matMenu">
                                <button mat-menu-item (click)="save(element.sys_id, column.name, null)"
                                    [hidden]="!element.data[column.name]">
                                    <i>-- Aucun --</i>
                                </button>
                                <button mat-menu-item *ngFor="let option of column.choices"
                                    (click)="save(element.sys_id, column.name, option)">
                                    <span>{{ option.label }}</span>
                                </button>
                            </mat-menu> -->
                        </span>
                        <span *ngSwitchCase="'amount'" [ngClass]="column.class">
                            {{ (element[column.name] | currency:"EUR") || "-" }}
                        </span>
                        <span *ngSwitchCase="'date'">
                            {{ (element[column.name] | date) || "-" }}
                        </span>
                        <span *ngSwitchCase="'checkbox'">
                            {{ (element[column.name] ? 'Oui' : 'Non') || "-" }}
                        </span>
                        <span *ngSwitchCase="'email'" [ngClass]="column.class">
                            {{ element[column.name] || "-" }}
                            <!-- <a href="mailto:{{element[column.name]}}">{{ element[column.name] }}</a> -->
                        </span>
                        <span *ngSwitchCase="'percentage'" [ngClass]="column.class">
                            {{ (element[column.name] / 100 | percent) || "-" }}
                            <!-- <span [hidden]="edit != element[id]" {{ (element[column.name] / 100 | percent) || "-" }}></span> -->
                            <!-- <span ng-if="edit == element[id]">
                                <form>
                                    <mat-form-field>
                                        <input matInput [(ngModel)]="element[column.name]" [name]="element."
                                    </mat-form-field>
                                </form>
                            </span> -->
                        </span>
                        <span *ngSwitchCase="'phone'" [ngClass]="column.class">
                            {{ element[column.name] || "-" }}
                            <!-- <a href="tel:{{element[column.name]}}" *ngIf="element[column.name]">{{ element[column.name]
                                || "-" }}</a> -->
                            <span *ngIf="!element.data[column.name]">-</span>
                        </span>
                        <span *ngSwitchCase="'template'" [innerHTML]="column.template(element)">
                        </span>
                        <div *ngSwitchDefault class="cell-text">
                            <span [innerHTML]="element[column.name]"></span>
                        </div>
                    </span>
                    <span *ngIf="column.suffix">{{ column.suffix }}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <span *ngIf="column.type === 'amount'">{{ sumRows(column) | currency: 'EUR' }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-center">
                </th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button [matMenuTriggerFor]="menu"
                        aria-label="Menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" xPosition="before">
                        <button mat-menu-item (click)="onRowClick(row)">
                            <mat-icon>edit</mat-icon>
                            <span>Modifier</span>
                        </button>
                    </mat-menu>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="headers"></tr>
            <tr mat-row *matRowDef="let row; columns: headers"></tr>
            <tr mat-footer-row *matFooterRowDef="headers" [hidden]="selection.selected.length == 0"></tr>

            <tr class="mat-row" [hidden]="!progress">
                <td class="mat-cell text-center" [attr.colspan]="columns.length + 1">
                    Chargement...<span> ({{ progress | percent }})</span>
                </td>
            </tr>
            <!-- Row shown when there is no matching data.-->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" [attr.colspan]="columns.length + 1">
                    Aucune donnée ne corresponds à votre recherche.
                </td>
            </tr>
        </table>
        <mat-paginator [pageSize]="50" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
    </div>
</div>