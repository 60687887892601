<span *ngIf="loading">Chargement...</span>
<form #form="ngForm">
    <div class="row" *ngFor="let section of sections; first as isFirst" [hidden]="!isSectionVisible(section)">
        <div class="form-section-header col-lg-12" *ngIf="section.displayTitle">
            <h5>{{ section.title }}<span *ngIf="section.hidden">...</span></h5>
        </div>
        <div class="col" *ngFor="let column of section.columns" [hidden]="section.hidden">
            <div *ngFor="let element of column.elements">
                <div [ngSwitch]="element.type" *ngIf="isVisible(element)">
                    <div class="form-group" *ngSwitchCase="'choice'">
                        <mat-form-field class="{{ element.class }}">
                            <mat-label>{{ element.label }}</mat-label>
                            <div *ngIf="element.grouped">
                                <mat-select [(ngModel)]="data[element.field]" [name]="element.field"
                                    [required]="isRequired(element)" [placeholder]="element.placeholder"
                                    (selectionChange)="onChange(element)">
                                    <mat-option value="" *ngIf="!isRequired(element)"><i>-- Aucun --</i></mat-option>
                                    <mat-optgroup *ngFor="let choice of element.choices" [label]="choice.label"
                                        [disabled]="!choice.subcategories">
                                        <mat-option *ngFor="let option of choice.subcategories" [value]="option.value">
                                            {{ option.label }}
                                        </mat-option>
                                    </mat-optgroup>
                                </mat-select>
                            </div>
                            <div *ngIf="!element.grouped">
                                <mat-select [(ngModel)]="data[element.field]" [name]="element.field"
                                    [required]="isRequired(element)" [placeholder]="element.placeholder"
                                    [multiple]="element.multiple" (selectionChange)="onChange(element)"
                                    [disabled]="isReadOnly(element)">
                                    <mat-option value="" *ngIf="!element.multiple && !isRequired(element)">
                                        <i>-- Aucun --</i>
                                    </mat-option>
                                    <mat-option *ngFor="let choice of getChoices(element)" [value]="choice.value">
                                        {{ choice.label }}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <mat-hint align="start" *ngIf="element.hint"><strong>{{ element.hint }}</strong> </mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngSwitchCase="'date'">
                        <mat-form-field class="{{ element.class }}">
                            <mat-label>{{ element.label }}</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="data[element.field]"
                                (dateChange)="onDateChanged(element, $event)" [name]="element.field"
                                [required]="isRequired(element)" [placeholder]="element.placeholder" autocomplete="off"
                                (focus)="picker.open()" (click)="picker.open()" [ngModelOptions]="{standalone: true}"
                                (keydown)="false" [disabled]="isReadOnly(element)">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-hint align="start" *ngIf="element.hint"><strong>{{ element.hint }}</strong> </mat-hint>
                            <button mat-button *ngIf="data[element.field] && !isReadOnly(element)" matSuffix
                                mat-icon-button aria-label="Clear" (click)="data[element.field]=''">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                        <!-- <span mat-suffix><mat-icon>calendar</mat-icon></span> -->
                    </div>
                    <div class="form-group" *ngSwitchCase="'reference'">
                        <app-form-field-reference [data]="data" [element]="element"></app-form-field-reference>
                    </div>
                    <div class="form-group" *ngSwitchCase="'file'">
                        <app-form-field-file [data]="data" [element]="element" [table]="record.sys_table" [id]="record.sys_id"></app-form-field-file>
                    </div>
                    <!-- <div class="form-group" *ngSwitchCase="'list-choice'">
                        <mat-form-field class="{{ element.class }}" *ngIf="isVisible(element)">
                            <mat-label>{{ element.label }}</mat-label>
                            <mat-chip-list #chipList aria-label="Fruit selection">
                                <mat-chip *ngFor="let chip of getChips(data[element.field], element.choices)" [selectable]="selectable"
                                    [removable]="removable" (removed)="remove(data[element.field], chip.value)">
                                    {{ chip.label }}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input [placeholder]="element.placeholder" [(ngModel)]="data[element.field]"
                                    [name]="element.field" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="add($event)">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected(data[element.field], $event)">
                                <mat-option *ngFor="let choice of element.choices" [value]="choice.value">
                                    {{ choice.label }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div> -->
                    <div class="form-group" *ngSwitchCase="'textarea'">
                        <mat-form-field class="{{ element.class }}">
                            <mat-label class="form-label">{{element.label}}</mat-label>
                            <textarea matInput type="text" [(ngModel)]="data[element.field]" [name]="element.field"
                                [required]="isRequired(element)" [placeholder]="element.placeholder" autocomplete="off"
                                (change)="onChange(element)" [rows]="element.rows || 5"
                                [disabled]="isReadOnly(element)">
                            </textarea>
                            <mat-hint align="start" *ngIf="element.hint"><strong>{{ element.hint }}</strong>
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngSwitchCase="'html'">
                        <div>
                            <editor [(ngModel)]="data[element.field]" [name]="element.field" [apiKey]="key" [init]="config"></editor>
                        </div>
                    </div>
                    <div class="form-group" *ngSwitchCase="'email'">
                        <mat-form-field class="{{ element.class }}">
                            <mat-label>{{ element.label }}</mat-label>
                            <input matInput type="email" email [(ngModel)]="data[element.field]" [name]="element.field"
                                [required]="isRequired(element)" (change)="onChange(element)"
                                [placeholder]="element.placeholder" autocomplete="off"
                                [disabled]="isReadOnly(element)" />
                            <span matSuffix *ngIf="element.suffix">{{ element.suffix }}</span>
                            <mat-hint align="start" *ngIf="element.hint"><strong>{{ element.hint }}</strong>
                            </mat-hint>
                            <mat-error [hidden]="element.field.valid || element.field.pristine">
                                Email non valide
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngSwitchCase="'url'">
                        <mat-form-field class="{{ element.class }}">
                            <mat-label>{{ element.label }}</mat-label>
                            <input matInput type="url" url [(ngModel)]="data[element.field]" [name]="element.field"
                                [required]="isRequired(element)" (change)="onChange(element)"
                                [placeholder]="element.placeholder" autocomplete="off"
                                [disabled]="isReadOnly(element)" />
                            <span matSuffix *ngIf="element.suffix">{{ element.suffix }}</span>
                            <mat-hint align="start" *ngIf="element.hint"><strong>{{ element.hint }}</strong>
                            </mat-hint>
                            <mat-error [hidden]="element.field.valid || element.field.pristine">
                                Url non valide
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngSwitchCase="'location'">
                        <mat-form-field class="{{ element.class }}">
                            <mat-label>{{ element.label }}</mat-label>
                            <input matInput type="url" url [(ngModel)]="data[element.field]" [name]="element.field"
                                [required]="isRequired(element)" (change)="onChange(element)"
                                [placeholder]="element.placeholder" autocomplete="off"
                                (keyup)="searchLocation($event, data[element.field])" [matAutocomplete]="auto"
                                [disabled]="isReadOnly(element)" />
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let location of (locations | async)"
                                    [value]="location.displayValue">
                                    <span>{{ location.displayValue }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span matSuffix *ngIf="element.suffix">{{ element.suffix }}</span>
                            <mat-hint align="start" *ngIf="element.hint"><strong>{{ element.hint }}</strong>
                            </mat-hint>
                            <mat-error [hidden]="element.field.valid || element.field.pristine">
                                Addresse non valide
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngSwitchCase="'integer'">
                        <mat-form-field class="{{ element.class }}">
                            <mat-label>{{ element.label }}</mat-label>
                            <input matInput type="number" [(ngModel)]="data[element.field]" [name]="element.field"
                                [required]="isRequired(element)" (change)="onChange(element)"
                                [placeholder]="element.placeholder" autocomplete="off"
                                [disabled]="isReadOnly(element)" />
                            <mat-hint align="start" *ngIf="element.hint"><strong>{{ element.hint }}</strong>
                            </mat-hint>
                            <span matSuffix *ngIf="element.suffix">{{ element.suffix }}</span>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngSwitchCase="'amount'">
                        <mat-form-field class="{{ element.class }}">
                            <mat-label>{{ element.label }}</mat-label>
                            <input matInput type="number" [(ngModel)]="data[element.field]" [name]="element.field"
                                [required]="isRequired(element)" [disabled]="isReadOnly(element)"
                                (change)="onChange(element)" [placeholder]="element.placeholder" autocomplete="off"
                                [disabled]="isReadOnly(element)" />
                            <mat-hint align="start" *ngIf="element.hint"><strong>{{ element.hint }}</strong>
                            </mat-hint>
                            <span matSuffix>€</span>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngSwitchCase="'checkbox'">
                        <mat-checkbox [(ngModel)]="data[element.field]" [name]="element.field">
                            {{ element.label }}
                        </mat-checkbox>
                    </div>
                    <div class="form-group" *ngSwitchCase="'password'">
                        <mat-form-field class="{{ element.class }}">
                            <mat-label>{{ element.label }}</mat-label>
                            <input matInput type="password" [(ngModel)]="data[element.field]" [name]="element.field"
                                [required]="isRequired(element)" [disabled]="isReadOnly(element)"
                                (change)="onChange(element)" [placeholder]="element.placeholder" autocomplete="off" />
                            <mat-hint align="start" *ngIf="element.hint"><strong>{{ element.hint }}</strong>
                            </mat-hint>
                            <span matSuffix *ngIf="element.suffix">{{ element.suffix }}</span>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngSwitchDefault>
                        <mat-form-field class="{{ element.class }}">
                            <mat-label>{{ element.label }}</mat-label>
                            <input matInput type="text" [(ngModel)]="data[element.field]" [name]="element.field"
                                [required]="isRequired(element)" [disabled]="isReadOnly(element)"
                                (change)="onChange(element)" [placeholder]="element.placeholder" autocomplete="off" />
                            <mat-hint align="start" *ngIf="element.hint"><strong>{{ element.hint }}</strong>
                            </mat-hint>
                            <span matSuffix *ngIf="element.suffix">{{ element.suffix }}</span>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-footer">
        <button mat-raised-button color="warning" [disabled]="!record.sys_id || saving || deleted" (click)="onDelete()"
            *ngIf="this.record.sys_id && access_control?.delete">
            {{ deleted ? "Chargement" : "Supprimer" }}
        </button>
        <button mat-raised-button [disabled]="!form.form.valid || saving || deleted" (click)="onInsertAndStay()"
            *ngIf="this.record.sys_id && access_control?.create">
            Dupliquer
        </button>
        <button type="submit" mat-raised-button color="primary"
            [disabled]="access_control?.update && (!form.form.valid || saving || deleted)" (click)="onSubmit()">
            {{ saving ? "Chargement" : "Sauvegarder" }}
        </button>
    </div>
</form>
<span *ngIf="saving">Enregistrement en cours...</span>