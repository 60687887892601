import { Component, Input, OnInit } from '@angular/core';
import { Record } from 'src/app/modules/core/models/record';
import { FirebaseManager } from 'src/app/modules/core/services/firebase/firebase-manager.service';

@Component({
  selector: 'app-team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.scss']
})
export class TeamCardComponent implements OnInit {
  @Input() id: string;

  club: Record;
  team: Record;

  constructor(private firebase: FirebaseManager) { }

  ngOnInit(): void {
    Promise.all([this.firebase.get('club', this.id),
     this.firebase.getCollection('user'),
     this.firebase.getCollection('team', query => query.where('club', '==', this.id))
    ]).then(
      result => {
        this.club = result[0];
        const users = result[1];
        this.club.data.teams = result[2];

        this.team = this.club.data.teams[0];
        
        if(this.club.data.manager){
          this.club.data.manager = users.find(user => user.sys_id == this.club.data.manager);
        }
      }
    )
  }

  onSelect(team){
    this.team = team;
  }
}
