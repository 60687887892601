import { Component, Input, OnInit } from '@angular/core';
import { ChoiceOption } from '../../models/choice-option/choice-option';
import { FormElement } from '../../models/form-element/form-element';
import { Record } from '../../models/record';
import { FirebaseManager } from '../../services/firebase/firebase-manager.service';

@Component({
  selector: 'app-form-field-reference',
  templateUrl: './form-field-reference.component.html',
  styleUrls: ['./form-field-reference.component.scss']
})
export class FormFieldReferenceComponent implements OnInit {
  @Input() element: FormElement;
  @Input() data: Record;

  isNew: boolean;

  constructor(private firebase: FirebaseManager) { }

  ngOnInit(): void {
    if (!this.data[this.element.field]) {
      this.data[this.element.field] = null;
    }

    this.firebase.getCollection(this.element.reference).then(records => {
      if (this.element.refQualifier) {
        const conditions = this.element.refQualifier;

        records = records.filter(record => conditions.reduce((output, condition) => {
          if (condition.operator === 'IN') {
            //TODO
            //Vérifier que la condition marche
            return output && condition.value.split(',').indexOf(record[condition.field]) > -1;
          }

          if (condition.operator == '!=') {
            return output && record[condition.field] != condition.value;
          }

          if (condition.operator === '==') {
            return output && record[condition.field] === condition.value;
          }
        }, true))
      }

      this.element.choices = records.map((record: Record) => {
        const columns = this.element.displayValue.split(',');
        const label = columns.reduce((output, column) => {
          if (record.data[column]) {
            output += ' ' + record.data[column];
          }

          return output;
        }, '');

        let dependantValue = null;

        if (this.element.dependantFrom && record.data[this.element.dependantFrom]) {
          dependantValue = record.data[this.element.dependantFrom];
        }

        return {
          table: record.sys_table,
          field: this.element.field,
          value: record?.sys_id,
          label: label,
          dependantValue: dependantValue,
          hint: record[this.element.hint]
        };
      });

      // Check if value exists
      const value = this.element.choices.find(choice => choice.value == this.data[this.element.field]);

      if(!value){
        this.data[this.element.field] = null;
      }

      console.log(this.data);
    }).catch(exception => console.warn(exception));
  }

  filterRef(choices: ChoiceOption[], dependantFrom: string): ChoiceOption[] {
    if (!choices) {
      return [];
    }

    if (!dependantFrom) {
      return choices;
    }

    return choices.filter(choice => choice.dependantValue === this.data[dependantFrom]);
  }

  isRequired(element: FormElement): boolean {
    try {
      if (element.isRequired === undefined) {
        return false;
      }

      if (typeof element.isRequired === 'function') {
        const isRequired = element.isRequired as Function;
        return isRequired(this.data);
      }

      const isRequired = element.isRequired as boolean;

      return isRequired;
    } catch (exception) {
      return true;
    }
  }

  isVisible(element: FormElement): boolean {
    try {
      if (element.isVisible === undefined) {
        return true;
      }

      if (element.isVisible instanceof Function) {
        const isVisible = element.isVisible(this.data);

        return isVisible;
      }

      const isVisible = element.isVisible as boolean;

      return isVisible;
    } catch (exception) {
      return true;
    }
  }

  isReadOnly(element: FormElement): boolean {
    try {
      if (element.isReadOnly === undefined) {
        return false;
      }

      if (typeof element.isReadOnly === 'function') {
        let isReadOnly = element.isReadOnly as Function;
        return isReadOnly(this.data);
      }

      let isReadOnly = element.isReadOnly as boolean;

      return isReadOnly;
    } catch (exception) {
      return false;
    }
  }

  onChange(element): void {
    if (element.onChange !== undefined) {
      element.onChange(this.data);
    }
  }

}
