import { Component, OnInit } from '@angular/core';
import { Club } from 'src/app/interfaces/club';
import { Record } from 'src/app/modules/core/models/record';
import { FirebaseManager } from 'src/app/modules/core/services/firebase/firebase-manager.service';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {
  clubs: Record[];

  constructor(private firebase: FirebaseManager) { }

  ngOnInit(): void {
    this.firebase.getCollection('club').then(
      clubs => this.clubs = clubs
    )
  }

}
