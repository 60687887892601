import { Component, OnInit, Input, OnChanges, ViewChild, ChangeDetectorRef, Output, EventEmitter, HostListener, SimpleChanges } from '@angular/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.

import { ActivatedRoute, Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormSection } from '../../models/form-section/form-section';
import { FormElement } from '../../models/form-element/form-element';
import { FormColumn } from '../../models/form-column/form-column';
import { APP_DATE_FORMATS } from '../../util/datepicker-format';
import { DateUtil } from '../../util/date-util';

// import { LocationService } from '../../services/location.service';
import { UINotificationService } from '../../services/ui-notification/ui-notification.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { environment } from 'src/environments/environment';
import { FirebaseManager } from '../../services/firebase/firebase-manager.service';
import { Record } from '../../models/record';
import { ChoiceOption } from '../../models/choice-option/choice-option';
import { AccessControl } from '../../models/access-control/access-control';
import { LocationService } from '../../services/location/location.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})

export class FormComponent implements OnInit, OnChanges {
  @Input() sections: FormSection[];
  @Input() table: string;
  @Input() id: string;
  @Input() title: string;
  @Input() page: string;
  @Input() record: Record;
  @Input() redirectTo: string;
  @Input() loading: boolean;
  @Input() access_control: AccessControl = new AccessControl();
  @ViewChild('auto') matAutocomplete: MatAutocompleteSelectedEvent;

  @Output() updated = new EventEmitter<Record>();

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 's') {
      this.onSubmit();
      event.preventDefault();
    }
  }

  key = environment.tiny.apiKey;
  config = environment.tiny.config;

  formChanged: boolean;
  data: any;
  deleted = false;
  saving: boolean;
  parameters: { field: string, value: string }[];
  locations: Promise<any[]>;
  isNew: boolean;

  constructor(private firebase: FirebaseManager,
    private router: Router,
    private route: ActivatedRoute,
    private location: LocationService,
    private notification: UINotificationService) { }

  ngOnInit(): void {
    this.loading = true;
    this.parameters = [];

    this.route.queryParamMap.subscribe(params => {
      const queryParams = params.get('sysparm_default');

      if (queryParams) {
        queryParams.split('^').forEach(condition => {
          const field = condition.split('=')[0];
          const value = condition.split('=')[1];

          if (value) {
            this.parameters.push({ field: field, value: value });
          }
        });
      }
    });

    this.sections.forEach(section => {
      section.columns.filter(column => column.elements).forEach(column => {
        column.elements.filter(element => element.type === 'reference').map(async element => {
          return await this.firebase.getCollection(element.reference).then(records => {
            if (element.refQualifier) {
              const conditions = element.refQualifier;

              records = records.filter(record => conditions.reduce((output, condition) => {
                if (condition.operator === 'IN') {
                  //TODO
                  //Vérifier que la condition marche
                  return output && condition.value.split(',').indexOf(record[condition.field]) > -1;
                }

                if (condition.operator == '!=') {
                  return output && record[condition.field] != condition.value;
                }

                if (condition.operator === '==') {
                  return output && record[condition.field] === condition.value;
                }
              }, true))
            }

            element.choices = records.map((record: Record) => {
              const columns = element.displayValue.split(',');
              const label = columns.reduce((output, column) => {
                if (record.data[column]) {
                  output += ' ' + record.data[column];
                }

                return output;
              }, '');

              let dependantValue = null;

              if (element.dependantFrom && record.data[element.dependantFrom]) {
                dependantValue = record.data[element.dependantFrom];
              }

              return {
                table: record.sys_table,
                field: element.field,
                value: record?.sys_id,
                label: label,
                dependantValue: dependantValue,
                hint: record[element.hint]
              };
            });

            return element;
          }).catch(error => this.notification.snack(error, 'danger'));
        });
      });
    });

    this.setDefaultValue(this.data, this.sections);
    this.formChanged = false;
    this.loading = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['record']) {
      if (!this.saving) {
        this.data = this.record.data;
        this.isNew = !this.record.sys_id;
        
        if (this.isNew) {
          this.setDefaultValue(this.data, this.sections);
        }

        this.setDefaultValue(this.data, this.sections);
      }
    }

    this.formChanged = false;
  }

  searchLocation($event: KeyboardEvent, address: string) {
    if ($event.key === 'ArrowDown' || $event.key === 'ArrowUp') {
      $event.preventDefault();
      return;
    }

    this.locations = this.location.search(address);
  }

  isRequired(element: FormElement): boolean {
    try {
      if (element.isRequired === undefined) {
        return false;
      }

      if (typeof element.isRequired === 'function') {
        const isRequired = element.isRequired as Function;
        return isRequired(this.data);
      }

      const isRequired = element.isRequired as boolean;

      return isRequired;
    } catch (exception) {
      return true;
    }
  }

  onInsertAndStay() {
    if (!confirm('Confirmez-vous que vous voulez dupliquer cet enregistrement ?')) {
      return;
    }

    delete this.record.sys_id;
    this.onSubmit();
  }

  onSubmit() {

    console.log(this.record);
    this.saving = true;
    this.record = this.formatValues(this.record);

    let errors = [];

    this.sections.forEach(section => {
      section.columns.forEach(column => {
        column.elements.forEach(element => {
          const isRequired = this.isRequired(element);
          const isVisible = this.isVisible(element);

          console.log(isRequired, this.data[element.field])

          if (isRequired && this.data[element.field] == null) {
            console.log('Field is required ', element);
            element['hasError'] = true;
            errors.push(element.label);
          }

          if (element.type == 'amount' || element.type == 'integer') {
            if (isNaN(this.data[element.field]) && !isVisible) {
              this.data[element.field] = 0
            }

            if (isNaN(this.data[element.field])) {
              element['hasError'] = true;
              errors.push(`${element.label} (valeur invalide)`);
            }

            this.data[element.field] = parseFloat(this.data[element.field]);
          }
        })
      })
    });

    if (errors.length > 0) {
      this.notification.snack(`Les champs suivant sont requis : ${errors.join(', ')}.`, 'danger');
      this.saving = false;
      return;
    }

    this.firebase.save<any>(this.record).then(
      (record: Record) => {
        this.updated.emit(record);
        this.saving = false;
        this.notification.snack('Enregistrée', 'info');

        if (this.isNew) {
          this.router.navigateByUrl(`admin/${this.record.sys_table}/edit/${record.sys_id}`);
        }
      }).catch(exception => {
        if (exception.details) {
          Object.keys(exception.details).forEach(key => {
            this.sections.forEach(section => section.columns.forEach(
              column => {
                const element = column.elements.find(element => element.field === key);

                if (element) {
                  const details = exception.details[key];
                  this.notification.snack(`Erreur sur le champ  ${element.label} (${details})`, 'danger');
                }
              })
            )
          })

          return;
        }

        this.notification.snack(exception.message, 'danger');
      })
      .finally(() => this.saving = false);
  }

  onDelete() {
    if (!confirm('Confirmez-vous la suppression ? Les pièces jointes associées seront également supprimées.')) {
      return;
    }

    this.deleted = true;
    this.firebase.delete(this.record)
      .then(() => this.router.navigateByUrl(this.redirectTo))
      .catch(exception => this.notification.snack(exception, 'danger'))
      .finally(() => this.deleted = true);
  }

  isSectionVisible(section: FormSection): boolean {
    try {
      if (section.isVisible === undefined) {
        return true;
      }

      if (section.isVisible instanceof Function) {
        return section.isVisible(this.data);
      }

      return section.isVisible as boolean;
    } catch (exception) {
      return true;
    }
  }

  isVisible(element: FormElement): boolean {
    try {
      if (element.isVisible === undefined) {
        return true;
      }

      if (element.isVisible instanceof Function) {
        const isVisible = element.isVisible(this.data);

        return isVisible;
      }

      const isVisible = element.isVisible as boolean;

      return isVisible;
    } catch (exception) {
      return true;
    }
  }

  isReadOnly(element: FormElement): boolean {
    try {
      if (element.isReadOnly === undefined) {
        return false;
      }

      if (typeof element.isReadOnly === 'function') {
        let isReadOnly = element.isReadOnly as Function;
        return isReadOnly(this.data);
      }

      let isReadOnly = element.isReadOnly as boolean;

      return isReadOnly;
    } catch (exception) {
      return false;
    }
  }

  onChange(element): void {
    if (element.onChange !== undefined) {
      element.onChange(this.data);
    }

    this.formChanged = true;
  }

  onDateChanged(element, event: MatDatepickerInputEvent<Date>) {
    if (element.onChange !== undefined) {
      element.onChange(this.data);
    }

    this.formChanged = true;
  }

  getChoices(element: FormElement) {
    if (element.dependantFrom) {
      return element.choices.filter(choice =>
        !choice.dependantValue || choice.dependantValue.split(',').indexOf(this.record[element.dependantFrom]) > -1);
    }

    return element.choices;
  }

  setDefaultValue(record: Record, sections: FormSection[]): void {
    sections.forEach(section => {
      if (!section.columns) {
        console.warn('No columns');
        return;
      }

      const data = record.data;

      if(!record || !data){
        return;
      }

      section.columns.filter(column => column.elements).forEach((column: FormColumn) => {
        column.elements.forEach(element => {
          if (element.field) {
            let defaultValue = element.defaultValue;

            if (!defaultValue) {
              const queryDefault = this.parameters?.find(parameter => parameter.field === element.field);

              if (queryDefault) {
                defaultValue = queryDefault.value;
              }
            }

            switch (element.type) {
              case 'date':
                if (data[element.field]) {
                  data[element.field] = new DateUtil().toFormat(data[element.field], 'yyyy-MM-DD');
                }

                break;
              case 'choice':
                if (data[element.field] && element.multiple) {
                  // data[element.field] = data[element.field].toString().split(',') || defaultValue;
                } else {
                  data[element.field] = data[element.field] || defaultValue;
                }

                break;
              case 'amount':
                if (data[element.field] === '' || isNaN(data[element.field])) {
                  data[element.field] = defaultValue;
                }

                break;
              case 'integer':
                if (isNaN(data[element.field])) {
                  data[element.field] = defaultValue;
                }

                break;
              default:
                data[element.field] = data[element.field] || defaultValue || null;
                break;
            }
          }
        })
      })
    });
  }

  filterRef(choices: ChoiceOption[], dependantFrom: string): ChoiceOption[] {
    if (!choices) {
      return [];
    }

    if (!dependantFrom) {
      return choices;
    }

    return choices.filter(choice => choice.dependantValue === this.record[dependantFrom]);
  }

  formatValues(record: Record) {
    this.sections.forEach(section => {
      if (!section.columns) {
        return record;
      }

      const data = record.data;

      section.columns.filter(column => column.elements).forEach((column: FormColumn) => {
        column.elements.forEach(element => {
          if (element.field) {
            if (element.type === 'date') {
              if (data[element.field]) {
                data[element.field] = new DateUtil().setDate(data[element.field]);
              } else {
                data[element.field] = null;
              }
            } else if (element.type === 'reference' || element.type === 'choice') {
              if (data[element.field] && element.multiple && data[element.field] instanceof Array) {
                // data[element.field] = data[element.field].join(',');
              }

              if (!data[element.field]) {
                data[element.field] = '';
              }
            } else if (element.type === 'integer' || element.type === 'percentage') {
              if (!isNaN(data[element.field])) {
                data[element.field] = parseFloat(data[element.field]);
              } else {
                data[element.field] = null;
              }
            } else if (element.type === 'amount') {
              if (!isNaN(data[element.field])) {
                data[element.field] = parseFloat(data[element.field]);
              } else {
                data[element.field] = null;
              }
            } else if (element.type === 'checkbox') {
              data[element.field] = data[element.field] == true;
            } else {
              if (data[element.field]) {
                data[element.field] = data[element.field].toString();
              } else {
                data[element.field] = '';
              }
            }
          }
        })
      })
    });

    return record;
  }

  listChoices = [];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  getChips(value: string, choices: ChoiceOption[]) {
    var output = [];

    var chip = choices.find(choice => choice.value == value);
    if (chip) {
      output.push(chip);
    }

    return output;
  }

  add(event: MatChipInputEvent): void {
    // console.log('Add ' + event.input + ' to ' + data);
    const input = event.input;
    const value = event.value;

    // Add our fruit
    // if ((value || '').trim()) {
    //   data.split(',').push(value.trim());
    // }
  }

  remove(inputs: string, chip: ChoiceOption): void {
    inputs = inputs.split(',').filter(input => input !== chip.value).join(',');
  }

  selected(value: string, event: MatAutocompleteSelectedEvent): void {
    value += event.option.viewValue + ',';
  }

  private _filter(choices: ChoiceOption[], value: string): ChoiceOption[] {
    const filterValue = value.toLowerCase();

    return choices.filter(choice => choice.label.toLowerCase().indexOf(filterValue) === 0);
  }
}
