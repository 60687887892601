import { Component, Input, OnInit } from '@angular/core';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';
import { FormElement } from '../../models/form-element/form-element';
import { Record } from '../../models/record';
import { FileStorageService } from '../../services/file-storage/file-storage.service';
import { UINotificationService } from '../../services/ui-notification/ui-notification.service';

@Component({
  selector: 'app-form-field-file',
  templateUrl: './form-field-file.component.html',
  styleUrls: ['./form-field-file.component.scss']
})

export class FormFieldFileComponent implements OnInit {
  @Input() element: FormElement;
  @Input() data: any;
  @Input() table: string;
  @Input() id: string;

  file: Record;
  isNew: boolean;
  fileName: string;
  progress: number;

  constructor(private firestore: FileStorageService, private notification: UINotificationService) { }

  ngOnInit(): void {
    if (!this.data[this.element.field]) {
      this.data[this.element.field] = null;
    }

    // if (this.table && this.id && this.element.field) {
    //   this.firestore.getFile(this.table, this.id, this.element.field).then(
    //     file => {
    //       if(file){
    //         this.file = file
    //         this.fileName = this.file.data.file_name;
    //         this.data[this.element.field] = this.file.sys_id;
    //       }
    //     }
    //   )
    // }
  }

  onUpload($event) {
    const _file: File = $event.target.files[0];

    this.firestore.upload(_file, this.table, this.id, this.element.field).subscribe(
      (task: UploadTaskSnapshot) => {
        this.progress = Math.round(task.bytesTransferred / task.totalBytes * 100);

        if (this.progress < 100) {
          return;
        }

        task.ref.getDownloadURL().then(url => {
          if (url) {
            this.data[this.element.field] = url;
          }
        });

        this.notification.snack('Fichier uploadé', 'info');
      }, error => {
        this.notification.snack(error, 'danger')
      });
  }

  onClear() {
    if (!confirm('Êtes-vous sûr.e de vouloir supprimer le fichier ?')) {
      return;
    }

    this.firestore.delete(this.data[this.element.field]).subscribe(
      () => {
        this.data[this.element.field] = null;
        this.notification.snack('Fichier supprimé', 'info')
      }
    )
  }

  isRequired(element: FormElement): boolean {
    try {
      if (element.isRequired === undefined) {
        return false;
      }

      if (typeof element.isRequired === 'function') {
        const isRequired = element.isRequired as Function;
        return isRequired(this.data);
      }

      const isRequired = element.isRequired as boolean;

      return isRequired;
    } catch (exception) {
      return true;
    }
  }

  isVisible(element: FormElement): boolean {
    try {
      if (element.isVisible === undefined) {
        return true;
      }

      if (element.isVisible instanceof Function) {
        const isVisible = element.isVisible(this.data);

        return isVisible;
      }

      const isVisible = element.isVisible as boolean;

      return isVisible;
    } catch (exception) {
      return true;
    }
  }

  isReadOnly(element: FormElement): boolean {
    try {
      if (element.isReadOnly === undefined) {
        return false;
      }

      if (typeof element.isReadOnly === 'function') {
        let isReadOnly = element.isReadOnly as Function;
        return isReadOnly(this.data);
      }

      let isReadOnly = element.isReadOnly as boolean;

      return isReadOnly;
    } catch (exception) {
      return false;
    }
  }

  onChange(element): void {
    if (element.onChange !== undefined) {
      element.onChange(this.data);
    }
  }
}
