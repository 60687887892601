import { Component, OnInit, Input } from '@angular/core';
import { Attachment } from '../../models/attachment/attachment';
import { FileStorageService } from '../../services/file-storage/file-storage.service';

@Component({
  selector: 'app-form-attachments',
  templateUrl: './form-attachments.component.html',
  styleUrls: ['./form-attachments.component.scss']
})
export class FormAttachmentsComponent implements OnInit {
  @Input() table: string;
  @Input() id: string;

  files: File[] = [];
  attachments: Attachment[] = [];
  edit: Attachment;
  uploading: Boolean;
  message: string;

  constructor(private storage: FileStorageService) { }

  ngOnInit(): void {
    if(!this.table || !this.id){
      return;
    }

    this.storage.getAttachments(this.table, this.id).then(
      attachments => this.attachments = attachments
    );
  }

  onDrop(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      const attachment = event.target.files[i];
      this.startUpload(attachment);
    }
  }

  onRename(attachment: Attachment) {
    this.storage.rename(attachment).catch(error => this.message = error).finally(() => this.edit = null);
  }

  startUpload(file: File) {
    let fileReader = new FileReader();
    this.uploading = true;

    fileReader.onload = (e) => {
      console.log(e);
      this.uploading = false;
    }

    // The main task
    // this.storage.upload(file, this.table, this.id);
  }

  deleteFile(id: string) {
    if (!confirm('Êtes-vous sûr.e de vouloir supprimer la pièce jointe?')) {
      return;
    }

    // this.storage.delete(id).toPromise().then(
    //   () => this.attachments = this.attachments.filter(attachment => attachment.id != id)
    // ).catch(error => this.message = error);
  }
}