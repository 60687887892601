import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChampionshipGame } from 'src/app/interfaces/championship-game';
import { ChampionshipManager } from 'src/app/services/championship/championship-manager.service';
import { Championship } from 'src/app/interfaces/championship';
import { ChampionshipDay } from 'src/app/interfaces/championship-day';
import { ChampionshipSeason } from 'src/app/interfaces/championship-season';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  loaded: boolean;
  datasource = { championships: [], seasons: [], days: [], games: [] };
  games: ChampionshipGame[];
  seasons: ChampionshipSeason[] = [];
  days: ChampionshipDay[] = [];
  championships: Championship[];
  filter: { 
    championship: Championship,
    season: ChampionshipSeason,
    day: ChampionshipDay 
  } = {
    championship: null,
    season: null,
    day: null
  };
  message: string;

  constructor(private championshipManager: ChampionshipManager,
    private location: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.loaded = false;

    Promise.all([
      this.championshipManager.getChampionships(),
      this.championshipManager.getSeasons(),
    ]).then(result => {
      this.championships = result[0];
      this.datasource.seasons = result[1];
    }).then(() => {
      this.location.queryParams.subscribe(params => {
        let championship = params['championship'];
        let season = params['season'];
        let day = params['day'];

        if (championship) {
          this.filter.championship =  this.championships.find(element => element.id == championship);
        } else {championship
          this.filter.championship = this.championships[1];
        }

        this.seasons = this.datasource.seasons.filter(season => season.championship == this.filter.championship.id);

        if (season) {
          this.filter.season =  this.seasons.find(element => element.id == season);
        } else {championship
          this.filter.season = this.seasons.find(season => season.current);

          if(!this.filter.season){
            this.filter.season = this.seasons[0];
          }
        }

        this.championshipManager.getChampionshipDays(this.filter.championship.id, this.filter.season.id).then(
          days => {
            this.days = days;

            if (day) {
              this.filter.day = this.days.find(element => element.id == day);
            } else {
              var nextDay = this.days.filter(element => element.state == 'scheduled');
              
              if(nextDay.length > 0){
                this.filter.day = nextDay[1];
              }else{
                this.filter.day = this.days[0];
              }
            }
  
            if (!championship || !championship || !day) {
              this.router.navigate(['/accueil'], {
                queryParams: {
                  championship: this.filter.championship.id,
                  season: this.filter.season.id,
                  day: this.filter.day?.id
                }
              });
            }
  
            this.loaded = true;
          }
        );
      })
    })
  }
}
