import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormCardComponent } from './components/form-card/form-card.component';
import { FormActionsComponent } from './components/form-actions/form-actions.component';
import { TableComponent } from './components/table/table.component';
import { FormAttachmentsComponent } from './components/form-attachments/form-attachments.component';
import { ListActionsComponent } from './components/list-actions/list-actions.component';
import { ListCardComponent } from './components/list-card/list-card.component';
import { RelatedListsComponent } from './components/related-lists/related-lists.component';
import { UploadTaskComponent } from './components/upload-task/upload-task.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SnackbarContentComponent } from './components/snackbar-content/snackbar-content.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FormComponent } from './components/form/form.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MatSelectModule } from '@angular/material/select';
import { FormFieldReferenceComponent } from './components/form-field-reference/form-field-reference.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormFieldFileComponent } from './components/form-field-file/form-field-file.component';

@NgModule({
  declarations: [
    FormComponent,
    FormCardComponent,
    FormActionsComponent,
    TableComponent,
    FormAttachmentsComponent,
    ListActionsComponent,
    ListCardComponent,
    RelatedListsComponent,
    UploadTaskComponent,
    UploaderComponent,
    SnackbarContentComponent,
    FormFieldReferenceComponent,
    FormFieldFileComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatTabsModule,
    MatCardModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatSortModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatSortModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    EditorModule
  ],
  exports: [
    FormCardComponent,
    FormActionsComponent,
    TableComponent,
    FormAttachmentsComponent,
    ListActionsComponent,
    ListCardComponent,
    RelatedListsComponent,
    UploadTaskComponent,
    UploaderComponent,
    SnackbarContentComponent
  ]
})
export class CoreModule { }
