import { Injectable } from '@angular/core';
import { Championship } from '../../interfaces/championship';
import { ChampionshipGame } from '../../interfaces/championship-game';
import { FirebaseManager } from 'src/app/modules/core/services/firebase/firebase-manager.service';
import { Club } from 'src/app/interfaces/club';
import { ChoiceList } from 'src/app/modules/core/models/choice-list/choice-list';
import { Record } from 'src/app/modules/core/models/record';
import { ChampionshipSeason } from 'src/app/interfaces/championship-season';
import { ChampionshipDay } from 'src/app/interfaces/championship-day';
import { Team } from 'src/app/interfaces/team';
import { templateSourceUrl } from '@angular/compiler';
import { FootballTeam } from 'src/app/models/footbeall-team.model';
import { dateInputsHaveChanged } from '@angular/material/datepicker/datepicker-input-base';

@Injectable({
providedIn: 'root'
})

export class ChampionshipManager {
constructor(private firebase: FirebaseManager) { }

getClubs(): Promise<Record[]> {
  return this.firebase.getCollection<Club>('club');
}

getTeam(id: string): Promise<Team> {
  return this.firebase.get<Club>('team', id).then(record => {
    var team = {
      id: record.sys_id,
      name: record.data['name'],
    } as Team;  

    return team;
  });
}

getTeams(championship?: String): Promise<FootballTeam[]> {
  return this.firebase.getCollection<Team>('team', query => {
    if(championship != null){
      query.where('championship', '==', championship) 
    }

    return query;
  }).then((records: Record[]) => {
    return records.filter(record => {
      if(championship){
        return record.data.championship == championship
      }
      
      return true;
    }).map(record => {
      var team = new FootballTeam();
      team.id  = record.sys_id;
      team.name = record.data.name;
      team.championship = record.data.championship;
      team.club = record.data.club;
      
      return team;
    })
  });
}

getSeasons(): Promise<ChampionshipSeason[]> {
  return this.firebase.getCollection<ChampionshipSeason>('championship_season').then((seasons: Record[]) => {
    return seasons.map((season: Record) => {
      return {
        id: season.sys_id,
        name: season.data.name,
        current: season.data.current,
        championship: season.data.championship
      } as ChampionshipSeason;
    })
  }).catch(exception => {
    console.warn(exception)
    return exception;
  });
}

getRanking(division: string, season: string, day: string): Promise<FootballTeam[]> {
  return Promise.all([
    this.getTeams(division), 
    this.getChampionshipGames(division, season),
    this.getChampionshipDays(division, season)
  ]).then(result => {
    let teams = result[0];
    let games = result[1];
    let days: ChampionshipDay[] = result[2];
    let championshipDay = days.find(element => element.id == day);
    days = days.filter(element => element.number <= championshipDay.number);

    // Get list of championship days
    games = games.filter(game => {
      return days.find(element => element.id == game.championship_day) != null;
    });

    games.forEach(game => {
      let hostTeam = teams.find((team: FootballTeam) => team.name === game.host);
      let guestTeam = teams.find(team => team.name == game.guest);

      const hostGoals = game.host_goals;
      const guestGoals = game.guest_goals;

      // Forfait: 4 points + 3 buts
      if (game.state === 'Forfait') {
        if (!game.withdrawn_team) {
          return;
        }

        if (game.withdrawn_team === 'host') {
          guestTeam.points += 4;
          guestTeam.conceded_goals += 3;

          game.guest_goals = 3;
          game.host_goals = 0;
        }

        if (game.withdrawn_team === 'guest') {
          hostTeam.points += 4;
          hostTeam.conceded_goals += 3;

          game.host_goals = 3;
          game.guest_goals = 0;
        }

        guestTeam.played += 1;
        hostTeam.played += 1;
      }

      if (game.state === 'Joué') {
        if (hostGoals > guestGoals) {
          hostTeam.points += 4;
          guestTeam.points += 1;

          hostTeam.won += 1;
          guestTeam.lost += 1;
        }

        if (hostGoals === guestGoals) {
          hostTeam.points += 2;
          guestTeam.points += 2;

          hostTeam.drawn += 1;
          guestTeam.drawn += 1;
        }

        if (hostGoals < guestGoals) {
          hostTeam.points += 1;
          guestTeam.points += 4;

          hostTeam.lost += 1;
          guestTeam.won += 1;
        }

        hostTeam.conceded_goals += game.guest_goals;
        guestTeam.conceded_goals += game.host_goals;
        hostTeam.scored_goals += game.host_goals;
        guestTeam.scored_goals += game.guest_goals;

        hostTeam.played += 1;
        guestTeam.played += 1;
      }
    });

    days.filter(day => day.team_referee != null).forEach(day => {
      var team = teams.find(element => element.id == day.team_referee);

      if(team){
        team.referee += 4;
        team.points += 4;
      }
    });

    teams.sort((teamA: FootballTeam, teamB: FootballTeam) => {
      if (teamA.points > teamB.points) {
        return -1;
      }

      if (teamA.points < teamB.points) {
        return 1;
      }

      return 0;
    });

    return teams;
  });
}

getChampionshipGames(championship: string, season: string, day?: string): Promise<ChampionshipGame[]> {
  return this.getTeams().then(teams => {
    return this.firebase.getCollection('championship_game',
      query => query.where('championship', '==', championship).where('championship_season', '==', season)
    ).then((games: Record[]) => {
      return games.filter(game => {
        let result = true;

        if (day) {
          result = result && game.data.championship_day == day && game.data.state != 'draft';
        }

        return result;
      }).map(record => {
        const host = teams.find(team => record.data.host === team.id);

        if (host) {
          record.data.host = host.name;
        }

        const guest = teams.find(team => record.data.guest === team.id);

        if (guest) {
          record.data.guest = guest.name;
        }

        record.data.state = new ChoiceList().getChoice('championship_game', 'state', record.data.state).label;

        return {
          sys_id: record.sys_id,
          sys_table: record.sys_table,
          ...record.data
        };
      });
    })
      .catch(exception => {
        console.warn(exception);
        throw exception;
      });
  })
}

getChampionshipDays(championship: string, season: string): Promise<ChampionshipDay[]> {
  return this.firebase.getCollection<ChampionshipDay>('championship_day',
    query => query.where('championship', '==', championship).where('championship_season', '==', season)
  ).then(days => days.map((day) => {
    const data = day.data as ChampionshipDay;
    return {
      id: day.sys_id,
      short_description: data.short_description,
      season: data.season,
      state: data.state,
      number: parseInt(data.number.toString()),
      championship: data.championship,
      exempted_team: data.exempted_team,
      team_referee: data.team_referee
    } as ChampionshipDay;
  }, []).sort((a: ChampionshipDay, b: ChampionshipDay) => {
    if (a.number > b.number) {
      return 1;
    }

    if (a.number < b.number) {
      return -1;
    }

    return 0;
  })
  );
}

getChampionships(): Promise<Championship[]> {
  return this.firebase.getCollection<Championship>('championship')
    .then((championships: Record[]) => {
      return championships.map((championship: Record) => {
        return {
          id: championship.sys_id,
          name: championship.data.name,
        } as Championship;
      })
    }).catch(exception => {
      console.warn(exception)
      return exception;
    });
}

getChampionshipDay(day: string): Promise<ChampionshipDay>{
  return this.firebase.get<ChampionshipDay>('championship_day', day).then((record) => {
    var day = {
      id: record.sys_id,
      number: record.data['number'],
      season: record.data['championship_season'],
      short_description: record.data['short_description'],
      championship: record.data['championship'],
      exempted_team: record.data['exempted_team']
    } as ChampionshipDay;

    return day;
  });
}
}
