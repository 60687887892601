import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FileStorageService } from '../../services/file-storage/file-storage.service';

interface IAttachment {
  id: string;
  file: string;
  name: string;
}

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})

export class UploadTaskComponent implements OnInit {
  @Input() file: File;
  @Input() table: string;
  @Input() target_id: string;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;
  uploading: boolean;
  attachment: IAttachment;

  constructor(private storage: FileStorageService) { }

  ngOnInit(): void {
    this.startUpload();
  }

  startUpload() {
    let fileReader = new FileReader();
    this.uploading = true;

    fileReader.onload = (e) => {
      this.uploading = false;
    }

    // The main task
    // this.storage.upload(this.file, this.table, this.target_id, this).subscribe(percent => {
    //   console.log(percent)
    // });

    // // Progress monitoring
    // this.percentage = this.task.percentageChanges();

    // this.snapshot   = this.task.snapshotChanges().pipe(
    //   tap(console.log),
    //   // The file's download URL
    //   finalize( async() =>  {
    //     this.downloadURL = await ref.getDownloadURL().toPromise();

    //     this.db.collection('files').add( { downloadURL: this.downloadURL, path });
    //   }),
    // );
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }
}
