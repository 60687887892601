<mat-card>
    <mat-card-header>
        <div mat-card-avatar class="example-header-image" style="background-image: url('{{ club.data.picture }}');"></div>
        <mat-card-title>
            <h3>{{ club.data.name }}</h3>
        </mat-card-title>
    </mat-card-header>
    <div *ngIf="team" class="teams">
        <img [src]="team.data.team_picture" width="100%" *ngIf="team.data.team_picture"/>
        <img [src]="team.data.team_picture" width="100%" height="200px" *ngIf="!team.data.team_picture"/>
    </div>
    <mat-card-content>
        <p>Stade : {{ club.data.address || '-' }}</p>
    </mat-card-content>
    <mat-card-actions>
        <!-- <a mat-stroked-button *ngIf="club.data.address" [routerLink]="['/routePath']" routerLinkActive="router-link-active">MAPS</a> -->
        <button mat-stroked-button (click)="onSelect(team)" *ngFor="let team of club.data.teams">{{ team.data.name }}</button>
    </mat-card-actions>
</mat-card>