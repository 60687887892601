<mat-toolbar>
    <div>
        <div class="title" [hidden]="options?.title.hidden">{{ title }}</div>
        <div class="subtitle text-muted" *ngIf="lastUpdate">
            Dernière sauvegarde : {{ lastUpdate | date: 'short' }}
        </div>
    </div>
    <div class="form-actions" *ngIf="record?.sys_id">
        <button mat-raised-button [color]="button.color" *ngFor="let button of  buttons" [hidden]="!isVisible(button)"
            (click)="onClick(button)">{{ button.label }}</button>
        <!-- <nb-actions size="medium" *ngIf="record && record.id != '-1'">
            <nb-action (click)="openAttachmentsWindow()">
                <i class="material-icons left">attach_file</i>
                <nb-badge *ngIf="record.attachments?.length > 0" text="{{ record.attachments.length }}" status="danger"
                    position="top right"></nb-badge>
            </nb-action>
        </nb-actions> -->
        <!-- <app-form-actions [actions]="actions" [record]="record"></app-form-actions> -->
        <button mat-icon-button (click)="goToRecord(previousRecord)" *ngIf="previousRecord" matTooltip="Aller à l'enregistrement précédant">
            <mat-icon>north</mat-icon>
        </button>
        <button mat-icon-button (click)="goToRecord(nextRecord)" *ngIf="nextRecord" matTooltip="Aller à l'enregistrement suivant">
            <mat-icon>south</mat-icon>
        </button>
    </div>
</mat-toolbar>
<div class="container">
    <div class="row">
        <div class="col form-container">
            <app-form [sections]="sections" [record]="record" [page]="options?.page" *ngIf="record" [loading]="loading"
                (updated)="refresh($event)" [redirectTo]="options?.redirectTo" [access_control]="_options.permissions">
            </app-form>
        </div>
    </div>
</div>