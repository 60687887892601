import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { Record } from 'src/app/modules/core/models/record';
import { FirebaseManager } from 'src/app/modules/core/services/firebase/firebase-manager.service';

@Component({
  selector: 'app-history-page',
  templateUrl: './history-page.component.html',
  styleUrls: ['./history-page.component.scss']
})
export class HistoryPageComponent implements OnInit {
  page: Record;
  isLoggedIn: Observable<any>;

  constructor(private firebase: FirebaseManager, private auth: AngularFireAuth) { }

  ngOnInit(): void {
    this.isLoggedIn = this.auth.authState;
    this.firebase.getRecord('sys_ui_page', query => query.where('path', '==', 'historique')).then(
      page => this.page = page
    );
  }

}
