import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { Record } from 'src/app/modules/core/models/record';
import { FirebaseManager } from 'src/app/modules/core/services/firebase/firebase-manager.service';

@Component({
  selector: 'app-reglement-interieur-page',
  templateUrl: './reglement-interieur-page.component.html',
  styleUrls: ['./reglement-interieur-page.component.scss']
})
export class ReglementInterieurPageComponent implements OnInit {
  page: Record;
  isLoggedIn: Observable<any>;

  constructor(private firebase: FirebaseManager, private auth: AngularFireAuth) { }

  ngOnInit(): void {
    this.isLoggedIn = this.auth.authState;
    this.firebase.getRecord('sys_ui_page', query => query.where('path', '==', 'reglement-interieur')).then(
      page => this.page = page
    );
  }

}
