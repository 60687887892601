import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChampionshipGame } from 'src/app/interfaces/championship-game';
import { Club } from 'src/app/interfaces/club';
import { Team } from 'src/app/interfaces/team';
import { ChampionshipManager } from 'src/app/services/championship/championship-manager.service';

@Component({
  selector: 'app-championship-result',
  templateUrl: './championship-result.component.html',
  styleUrls: ['./championship-result.component.scss']
})
export class ChampionshipResultComponent implements OnInit {
  games: ChampionshipGame[] = [];
  exemptedTeam: Promise<Team>;
  loading: boolean;

  constructor(private championshipManager: ChampionshipManager, private location: ActivatedRoute) { }

  ngOnInit() {
    this.location.queryParams.subscribe(params => {
      const division = params['championship'];
      const season = params['season'];
      const day = params['day'];

      if(!division || !season || !day){
        return [];
      }

      this.championshipManager.getChampionshipGames(division, season, day).then(
        games => this.games = games
      ).finally(
        () => this.loading = false
      );

      this.championshipManager.getChampionshipDay(day).then(championshipDay => {
        var exemptedTeam = championshipDay.exempted_team;

        if(!exemptedTeam){
          return;
        }

        this.exemptedTeam = this.championshipManager.getTeam(exemptedTeam);
      })
    });
  }
}
