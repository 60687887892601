import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { RelatedList } from '../../models/related-list/related-list';

@Component({
  selector: 'app-related-lists',
  templateUrl: './related-lists.component.html',
  styleUrls: ['./related-lists.component.scss']
})

export class RelatedListsComponent implements OnInit {
  @Input() lists: RelatedList[];
  
  _lists: RelatedList[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChange): void {
    if(changes['lists']){
      this._lists = changes['lists'].currentValue;
    }
  }

}
