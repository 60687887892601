<div *ngIf="page" class="container">
    <div class="row">
        <div class="col">
            <h1>
                {{ page.data.title }}
                <a mat-icon-button [routerLink]="['/admin/formulaire', 'sys_ui_page', page.sys_id]"
                 routerLinkActive="router-link-active" *ngIf="(isLoggedIn|async)">
                    <mat-icon>edit</mat-icon>
                </a>
            </h1>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div [innerHTML]="page.data.content"></div>
        </div>
    </div>
</div>