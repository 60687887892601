<mat-card>
    <h1>Classement</h1>
    <hr />
    <table>
        <thead>
            <tr>
                <th></th>
                <th>Club</th>
                <th>Joués</th>
                <th>Points</th>
                <th [hidden]="(isHandset$ | async)">Gagnés</th>
                <th [hidden]="(isHandset$ | async)">Nuls</th>
                <th [hidden]="(isHandset$ | async)">Perdus</th>
                <th [hidden]="(isHandset$ | async)">Buts pour</th>
                <th [hidden]="(isHandset$ | async)">Buts contre</th>      
                <th [hidden]="(isHandset$ | async)">Diff.</th>
                <th [hidden]="(isHandset$ | async)">Bonus</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let team of teams; index as i">
                <td class="text-center">{{ i + 1}} </td>
                <td class="text-center">{{ team.name }} </td>
                <td width="text-center">{{ team.played }} </td>
                <td class="text-center">{{ team.points }} </td>
                <td width="text-center" [hidden]="(isHandset$ | async)">{{ team.won }} </td>
                <td width="text-center" [hidden]="(isHandset$ | async)">{{ team.drawn }} </td>
                <td width="text-center" [hidden]="(isHandset$ | async)">{{ team.lost }} </td>
                <td width="text-center" [hidden]="(isHandset$ | async)">{{ team.scored_goals }} </td>
                <td width="text-center" [hidden]="(isHandset$ | async)">{{ team.conceded_goals }} </td>
                <td width="text-center" [hidden]="(isHandset$ | async)">{{ team.scored_goals - team.conceded_goals }}</td>
                <td width="text-center" [hidden]="(isHandset$ | async)">{{ team.referee }}</td>
            </tr>
        </tbody>
    </table>
</mat-card>