import { Component, OnInit, Input, Output, SimpleChange, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FormAction } from '../../models/form-action/form-action';

@Component({
  selector: 'app-list-actions',
  templateUrl: './list-actions.component.html',
  styleUrls: ['./list-actions.component.scss']
})
export class ListActionsComponent implements OnInit, OnChanges {
  @Input() actions: FormAction[] = [];
  @Output() keywords: string;

  primaryActions: FormAction[] = [];
  secondaryActions: FormAction[] = [];

  constructor(private location: Router) { }

  ngOnInit(): void {
    if (this.actions) {
      this.primaryActions = this.actions.filter(action => action.primary && this.isVisible(action));
      this.secondaryActions = this.actions.filter(action => !action.primary && this.isVisible(action));
    }
  }

  ngOnChanges(): void {
    if (this.actions) {
      this.primaryActions = this.actions.filter(action => action.primary && this.isVisible(action));
      this.secondaryActions = this.actions.filter(action => !action.primary && this.isVisible(action));
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.keywords = filterValue.trim().toLowerCase();
  }

  onClick(action: FormAction) {
    try {
      if(action.type === 'script'){
        action.onClick();
      }

      if(action.type === 'link'){
        this.location.navigate([action.link], {queryParams: action.queryParams});
      }

    } catch (exception) {
      console.warn(exception);
    }
  }

  isVisible(action: FormAction): boolean {
    try {
      if (action.isVisible === undefined) {
        return true;
      }

      if (action.isVisible instanceof Function) {
        const isVisible = action.isVisible as Function;
        return isVisible();
      }

      const isVisible = action.isVisible as boolean;

      return isVisible;
    } catch (exception) {
      return true;
    }
  }
}
