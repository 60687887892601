<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <h1>
                Sponsors
                <div class="pull-right">
                    <form #form="ngForm">
                        <input [(ngModel)]="keyword" name="keyword" (onkeyup)="filter()" class="form-control" placeholder="Rechercher une entreprise...">
                    </form>
                </div>
            </h1>
        </div>
    </div>
    <br/>
    <hr/>
    <div class="row">
        <div class="col-lg-12">
            <div [hidden]="!loading" class="text-center">Chargement...</div>
            <table>
                <tbody>
                    <tr *ngFor="let sponsor of filter()">
                        <td>
                            <img [src]="sponsor.image.url" [alt]="sponsor.image.title" width="200px" (click)="zoomIn(sponsor.image.url, sponsor.image.title)">
                        </td>
                        <td class="align-top">
                            <h3>{{ sponsor.company }}</h3>
                            <span class="text-muted">{{ sponsor.description }}</span>
                            <p>
                                Téléphone : {{ sponsor.phone || " - "}}
                            </p>
                            <p>
                                Site web : {{ sponsor.website || " - "}}
                            </p>
                            <p>
                                Adresse : {{ sponsor.address || " - "}}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>