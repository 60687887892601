import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent{
  isHovering: boolean;
  files: File[] = [];
  uploading: boolean;

  toggleHover(event: boolean){
    this.isHovering = event;
  }

  onDrop(event){
    for(let i=0; i < event.target.files.length; i++){
      this.files.push(event.target.files[i]);
    }
  }
}
