import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TeamsComponent } from './pages/teams/teams.component';
import { HistoryPageComponent } from './pages/history-page/history-page.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PresentationPageComponent } from './pages/presentation-page/presentation-page.component';
import { ReglementInterieurPageComponent } from './pages/reglement-interieur-page/reglement-interieur-page.component';
import { SponsorsPageComponent } from './pages/sponsors-page/sponsors-page.component';
import { NewsPageComponent } from './pages/news-page/news-page.component';
import { TournamentPageComponent } from './pages/tournament-page/tournament-page.component';
import { AuthGuard } from './modules/user/auth-guard.guard';
import { ContentPageComponent } from './pages/content-page/content-page.component';

const routes: Routes = [
  { path: '', redirectTo: 'accueil', pathMatch: 'full' },
  { path: 'accueil', component: HomepageComponent },
  { path: 'partenaires', component: SponsorsPageComponent },
  { path: 'clubs', component: TeamsComponent },
  { path: 'tournoi', component: TournamentPageComponent },
  { path: 'actualites', component: NewsPageComponent },
  { path: 'historique', component: ContentPageComponent },
  { path: 'reglement-interieur', component: ContentPageComponent },
  { path: 'palmares', component: ContentPageComponent },
  { path: 'presentation', component: ContentPageComponent },
  { path: 'statuts', component: ContentPageComponent },
  { path: 'bureau-du-challenge-celtic', component: ContentPageComponent },
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard]},
  { path: 'user', loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)},
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
