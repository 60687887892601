import { Component, OnInit } from '@angular/core';
import { SponsorImageComponent } from 'src/app/components/sponsor-image/sponsor-image.component';
import { SponsorService } from 'src/app/services/sponsor/sponsor.service';
import { SponsorInterface } from 'src/app/interfaces/sponsor';
import { MatDialog } from '@angular/material/dialog';
import { Sponsor } from 'src/app/models/sponsor/sponsor';

@Component({
  selector: 'app-sponsors-page',
  templateUrl: './sponsors-page.component.html',
  styleUrls: ['./sponsors-page.component.scss']
})
export class SponsorsPageComponent implements OnInit {
  sponsors: Sponsor[];
  keyword: string;
  loading: boolean;

  constructor(private sponsorManager: SponsorService, private dialog: MatDialog) { }

  ngOnInit() {
    this.loading = true;

    this.sponsorManager.getSponsors().then(
      sponsors => {
        this.sponsors = sponsors;
      }
    ).catch(exception => console.warn(exception))
      .finally(
        () => this.loading = false
      );
  }

  zoomIn(url:string, company: string){
    this.dialog.open(SponsorImageComponent, {
      data: {
        url: url,
        company: company
      }
    })
  }

  filter() {
    if (!this.keyword) {
      return this.sponsors;
    }

    return this.sponsors.filter(sponsor =>
      sponsor.company.toLocaleLowerCase().indexOf(this.keyword.toLocaleLowerCase()) > -1 ||
      (sponsor.description && sponsor.description.indexOf(this.keyword.toLowerCase()) > -1)
    );
  }
}
