<div *ngIf="loaded">
    <header>
        <div class="title-container">
            <h1>Bienvenue sur le site<br /> du Challenge Celtic</h1>
            <div class="filter-container">
                <div>
                    <button mat-raised-button color="primary" [matMenuTriggerFor]="divisionsList">
                        {{ filter.championship.name }}
                        <mat-icon>expand_more</mat-icon>
                    </button>
                    <mat-menu #divisionsList="matMenu">
                        <button mat-menu-item *ngFor="let championship of championships"
                            [routerLink]="['/accueil']"
                            [queryParams]="{ championship: championship.id }"
                            routerLinkActive="router-link-active">
                            {{ championship.name }}
                        </button>
                    </mat-menu>
                </div>
                <div>
                    <button mat-raised-button color="primary" [matMenuTriggerFor]="seasonsList">
                        {{ filter.season.name }}
                        <mat-icon>expand_more</mat-icon>
                    </button>
                    <mat-menu #seasonsList="matMenu">
                        <button mat-menu-item *ngFor="let season of seasons"
                            [routerLink]="['/accueil']"
                            [queryParams]="{ championship: filter.championship.id, season: season.id }"
                            routerLinkActive="router-link-active">
                            {{ season.name }}
                        </button>
                    </mat-menu>
                </div>
                <div>
                    <button mat-raised-button color="primary" [matMenuTriggerFor]="dayList">
                        {{ filter.day.short_description }}
                        <mat-icon>expand_more</mat-icon>
                    </button>
                    <mat-menu #dayList="matMenu">
                        <button mat-menu-item *ngFor="let day of days" 
                            [routerLink]="['/accueil']"
                            [queryParams]="{championship: filter.championship?.id, season: filter.season.id, day: day.id}"
                            class="day-link"
                            routerLinkActive="router-link-active">
                            {{ day.short_description }}
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <app-championship-result></app-championship-result>
    </header>
    <section>
        <app-championship-ranking></app-championship-ranking>
    </section>
</div>