<mat-form-field class="{{ element.class }}">
    <mat-label>{{ element.label }}</mat-label>
    <mat-select [(ngModel)]="data[element.field]" [name]="element.field" [required]="isRequired(element)"
        [placeholder]="element.placeholder" (selectionChange)="onChange(element)" [disabled]="isReadOnly(element)">
        <mat-option [value]="" *ngIf="!isRequired(element)"><i>-- Aucun --</i></mat-option>
        <mat-option *ngFor="let choice of filterRef(element.choices, element.dependantFrom)" [value]="choice.value">
            {{ choice.label }}
            <span class="text-muted" *ngIf="choice.hint"> | {{ choice.hint }}</span>
        </mat-option>
    </mat-select>
    <a [routerLink]="['/' + element.page, data[element.field]]" *ngIf="element.page && data[element.field] && !isNew"
        matSuffix mat-icon-button aria-label="Aller sur la fiche {{ element.label }}">
        <mat-icon>launch</mat-icon>
    </a>
    <mat-hint align="start" *ngIf="element.hint"><strong>{{ element.hint }}</strong> </mat-hint>
</mat-form-field>