<mat-card>
    <mat-card-header>
        <div class="mat-card-header-container">
            <h1>{{ title }}</h1>
        </div>
        <div class="list-actions">
            <!-- <div class="list-inline-item">
                <button mat-icon-button (click)="filter.expanded = !filter.expanded" matTooltip="Filtres avancés">
                    <mat-icon>filter_alt_outline</mat-icon>
                </button>
            </div> -->
            <div class="list-inline-item">
                <button mat-icon-button [matMenuTriggerFor]="sortMenu" aria-label="Trier les données"
                    matTooltip="Trier les données">
                    <mat-icon>filter_list</mat-icon>
                </button>
                <mat-menu #sortMenu="matMenu">
                    <button mat-menu-item *ngFor="let field of filter.fields" (click)="sortBy(field)">
                        <mat-icon *ngIf="sort?.field != field.name"></mat-icon>
                        <mat-icon *ngIf="sort?.field == field.name && sort.direction == 'asc'">north</mat-icon>
                        <mat-icon *ngIf="sort?.field == field.name && sort.direction == 'desc'">south</mat-icon>
                        <span>{{ field.label }}</span>
                    </button>
                </mat-menu>
            </div>
            <div class="list-inline-item">
                <mat-form-field>
                    <input matInput type="text" mat- (keyup)="applyFilter($event)" placeholder="Rechercher"  matTooltip="Rechercher par mot clé" #input>
                </mat-form-field>
            </div>
            <app-list-actions [actions]="actions"></app-list-actions>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="filter-box" [hidden]="!filter.expanded">
            <h5>Filtres</h5>
            <div>
                <form>
                    <ul class="list-inline">
                        <li class="list-inline-item" *ngFor="let condition of filter.conditions; let i = index">
                            <!-- Field -->
                            <mat-form-field>
                                <mat-label class="form-label">Champ</mat-label>
                                <mat-select [(ngModel)]="filter.conditions[i].field" name="field">
                                    <mat-option *ngFor="let field of filter.fields" [value]="field">
                                        {{ field.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            &nbsp;
                            <!-- Operator -->
                            <mat-form-field *ngIf="filter.conditions[i].field as field">
                                <mat-select [(ngModel)]="filter.conditions[i].operator" name="operator">
                                    <mat-option *ngFor="let operator of field.operators" [value]="operator">
                                        {{ operator.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            &nbsp;
                            <!-- Value -->
                            <span [ngSwitch]="field.type" *ngIf="filter.conditions[i].field as field">
                                <span *ngSwitchCase="'date'">
                                    <mat-form-field>
                                        <input matInput [matDatepicker]="picker"
                                            [(ngModel)]="filter.conditions[i].value" name="value">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                    <!-- <mat-form-field>
                                        <mat-date-range-input [rangePicker]="picker">
                                            <input matStartDate [(ngModel)]="filter.conditions[i].start" name="start"
                                                placeholder="Start date">
                                            <input matEndDate [(ngModel)]="filter.conditions[i].end" name="end"
                                                placeholder="End date">
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-date-range-picker #picker></mat-date-range-picker>
                                    </mat-form-field> -->
                                </span>
                                <span *ngSwitchDefault>
                                    <mat-form-field>
                                        <input matInput [(ngModel)]="filter.conditions[i].value" name="value" />
                                    </mat-form-field>
                                </span>
                            </span>
                        </li>
                        <li class="list-inline-item">
                            <button mat-icon-button (click)="applyFilters()">
                                <mat-icon>search</mat-icon>
                            </button>
                            &nbsp;
                            <button mat-icon-button (click)="clearFilter()">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </li>
                    </ul>
                    <hr />
                </form>
            </div>
        </div>
        <span *ngIf="loading">Chargement...</span>
        <div *ngIf="!loading">
            <app-table [columns]="columns" [records]="_records" [redirectTo]="page" [keywords]="keywords" [conditions]="_conditions" [sortBy]="sort">
        </app-table>
        </div>
    </mat-card-content>
</mat-card>