import { Attachment } from "./attachment/attachment";

export class Record {
    sys_id: string;
    sys_table: string;
    sys_created_on: Date;
    sys_updated_on: Date;
    data: any;
    visible: boolean = true;
    attachments: Attachment[];

    constructor(table: string, id?: string){
        this.sys_table = table;
        this.sys_id = id;
        this.attachments = [];
        this.data = {};
    }

    get link(): string{
        return `/admin/${this.sys_table}/edit/${this.sys_id}`;
    }
}
