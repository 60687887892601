<!-- <mat-form-field class="{{ element.class }}">
    <mat-label>{{ element.label }}</mat-label>
    <input matInput [(ngModel)]="fileName" name="fileName" readonly/>
    <button mat-icon-button matSuffix (click)="hiddenfileinput.click()">
        <mat-icon>attachment</mat-icon>
    </button>
    <button mat-icon-button matSuffix (click)="onPreview()" *ngIf="fileName">
        <mat-icon>preview</mat-icon>
    </button>
    <button mat-icon-button matSuffix (click)="onClear()" *ngIf="fileName">
        <mat-icon>clear</mat-icon>
    </button>
</mat-form-field>

<input type="file" (change)="onUpload($event)" #hiddenfileinput style="display: none"> -->

<mat-label>{{ element.label }}</mat-label>
<div *ngIf="data[element.field]">
    <img [src]="data[element.field]" width="auto" height="200px"/>
</div>
<div class="img-actions">
    <button mat-button matSuffix (click)="hiddenfileinput.click()">
        <mat-icon>attachment</mat-icon>&nbsp;Upload
    </button>
    <button mat-button matSuffix (click)="onClear()" *ngIf="data[element.field]">
        <mat-icon>clear</mat-icon>&nbsp;Supprimer
    </button>
</div>

<input type="file" (change)="onUpload($event)" #hiddenfileinput style="display: none">