import { QueryValueType } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { Post } from 'src/app/modules/admin/schema/post.service';
import { Record } from 'src/app/modules/core/models/record';
import { FirebaseManager } from 'src/app/modules/core/services/firebase/firebase-manager.service';

@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.scss']
})
export class NewsPageComponent implements OnInit {
  posts: Record[];

  constructor(private firebase: FirebaseManager) { }

  ngOnInit(): void {
    this.firebase.getCollection('article', query => query.where('category', 'in', ['news','meeting'])
      .where('state', '==', 'published').orderBy('sys_created_on', 'desc')
    ).then(
      posts => this.posts = posts
    )
  }

}
