import { Record } from "../record";


export class Attachment extends Record {
    id: string;
    file: string;
    name: string;
    format: string;
    content_type: string;
    object_id: string

    constructor(){
        super('attachment');
    }
}
