import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction, QueryFn, DocumentReference } from '@angular/fire/firestore';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { Record } from '../../models/record';
import { HttpErrorResponse } from '@angular/common/http';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { DateUtil } from '../../util/date-util';

@Injectable({
  providedIn: 'root'
})

export class FirebaseManager {

  constructor(private db: AngularFirestore) { }

  colWithIds$<T>(ref: string, queryFn?: QueryFn): Observable<any[]> {
    if (!ref) {
      throw 'error';
    }

    return this.db.collection(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((actions: DocumentChangeAction<T>[]) => {
          var records = actions.map((a: DocumentChangeAction<T>) => {
            const data: Object = a.payload.doc.data();
            const id = a.payload.doc.id;

            Object.keys(data).map(key => {
              if(data[key] instanceof Timestamp){
                data[key] = new Date(data[key]);
              }
            })

            return { id, ...data };
          });

          return records;
        }),
      )
  }

  async get<T>(table: string, id: string): Promise<Record> {
    try {
      const record = new Record(table);

      if (id == undefined) {
        console.warn('Id is undefined');
        return record;
      }

      if (id == '-1') {
        return record;
      }

      return this.db.collection(table).doc(id).get().pipe(
        map(document => {
          if (!document.exists) {
            throw 'Record does not exist';
          }
          
          let record = new Record(table, id);
          record.data = document.data() as T;

          Object.keys(record.data).map(key => {
            let data = record.data[key];

            if(!data){
              return record;
            }
            
            if(typeof data === "object" && data.seconds){
              record.data[key] = new DateUtil().timestampToDate(data.seconds);
            }
          })

          return record;
        }),
        catchError((error) => {
          if(error.code == 'permission-denied'){
            throw 'Vous n\'êtes pas autorisé à voir cet enregistrement';
          } 

          throw 'Une erreur est survenue. Veuillez réessayer.';
        }),
        take(1)
      ).toPromise();
    } catch (exception) {
      throw exception
    }

  }

  async getRecord<T>(table: string, queryFn: QueryFn): Promise<Record> {
    try {
      if (!table) {
        throw 'No table defined';
      }

      return this.db.collection(table, queryFn)
        .snapshotChanges()
        .pipe(
          map((actions: DocumentChangeAction<any>[]) => {
            var records = actions.map(action => {
              const data: T = action.payload.doc.data();
              const id = action.payload.doc.id;

              let record = new Record(table, id);
              record.data = data;

              return record;
            });

            return records[0];
          }),
          take(1)
        ).toPromise();
    } catch (exception) {
      throw exception;
    }
  }

  async getCollection<T>(table: string, query?: QueryFn): Promise<Record[]> {
    try {
      if (!table) {
        throw 'No table defined';
      }

      return this.db.collection(table, query).snapshotChanges().pipe(
        map((actions: DocumentChangeAction<T>[]) => {
          var records = actions.map((action: DocumentChangeAction<T>) => {
            const data: Object = action.payload.doc.data();
            const id = action.payload.doc.id;

            let record = new Record(table, id);
            record.data = data as T;

            Object.keys(data).map(key => {
              if(data[key] && data[key].seconds){
                data[key] = new Date(data[key].seconds * 1000);
              }

              return key;
            })

            return record;
          })

          return records;
        }),
        take(1),
        catchError(error => {
          throw 'Une erreur est survenu. Veuillez réessayer.';
        })
      ).toPromise();
    } catch (exception) {
      throw exception;
    }
  }

  getPreviousRecord(table: string, sys_id: string, orderBy: string, direction: 'asc' | 'desc'): Promise<Record>{
    return this.getCollection(table).then((records: Record[]) => {
      const index = records.sort((a, b) => {
        if(a[orderBy] < b[orderBy]){
          return 1;
        }

        if(a[orderBy] > b[orderBy]){
          return -1;
        }

        return 0;
      }).findIndex(record => record.sys_id == sys_id);

      if(index <= 0){
        return null;
      }

      return records[index - 1];
    });
  }

  getNextRecord(table: string, sys_id: string, orderBy: string, direction: 'asc' | 'desc'): Promise<Record>{
    return this.getCollection(table).then((records: Record[]) => {
      const index = records.sort((a, b) => {
        if(a[orderBy] < b[orderBy]){
          return 1;
        }

        if(a[orderBy] > b[orderBy]){
          return -1;
        }

        return 0;
      }).findIndex(record => record.sys_id == sys_id);

      if((index + 1) > records.length){
        return null;
      }

      return records[index + 1];
    });
  }

  save<T>(record: Record): Promise<Record | void> {
    try {
      if (!record.sys_table) {
        throw 'No table defined';
      }

      if (record.data['id']) {
        delete record.data['id'];
      }

      if (record.sys_id) {
        return this.db.collection(record.sys_table).doc(record.sys_id).update(Object.assign({}, {
          sys_updated_on: new Date(),
          sys_table: record.sys_table,
          ...record.data
        })).then(() => {
          return record;
        }).catch(exception => {
          throw exception;
        })
      }

      return this.db.collection(record.sys_table).add(Object.assign({}, {
        sys_created_on: new Date(),
        sys_updated_on: new Date(),
        sys_table: record.sys_table,
        ...record.data
      })).then((response: DocumentReference) => {
        record.sys_id = response.id;

        return record;
      }).catch(error => {
        throw error
      });
    } catch (exception) {
      throw exception;
    }
  }

  delete<T>(record: Record): Promise<void> {
    return this.db.collection(record.sys_table).doc(record.sys_id).delete();
  }

  private handleError(response: HttpErrorResponse) {
    try {
      let errorMessage = 'An unknown error occurred!';
      if (!response.error) {
        return throwError(errorMessage);
      }

      const error = response.error.error;

      switch (error) {
        case 'invalid_grant':
          errorMessage = 'Identifiants incorrects';
          break;
        case 'User with this Email address already exists.':
          errorMessage = 'This email is already used.';
          break;
        case 'INVALID_PASSWORD':
          errorMessage = 'This password is not correct.';
          break;
      }

      return throwError(errorMessage);
    } catch (exception) {
      console.warn(exception);
      return throwError('Unknow error');
    }
  }
}
