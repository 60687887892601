import { Component, Input, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sponsor-image',
  templateUrl: './sponsor-image.component.html',
  styleUrls: ['./sponsor-image.component.scss']
})
export class SponsorImageComponent implements OnInit {
  @Input() url: string;
  @Input() company: string;
  
  constructor(public dialogRef: MatDialogRef<SponsorImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
