<ul class="list-inline">
    <li class="list-inline-item" *ngFor="let action of primaryActions" [hidden]="!isVisible(action)">
        <button mat-raised-button (click)="onClick(action)" color="primary">
            <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
            <span>{{ action.label }}</span>
        </button>
    </li>
    <li class="list-inline-item" *ngIf="secondaryActions?.length > 0">
        <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu" aria-label="Actions">
            Actions&nbsp;<mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let action of secondaryActions" [hidden]="!isVisible(action)">
                <span (click)="onClick(action)">
                    <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
                    <span>{{ action.label }}</span>
                </span>
            </button>
        </mat-menu>
    </li>
</ul>