import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './modules/layout/layout.module';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { CoreModule } from './modules/core/core.module';

// LANGUAGES
import localeFr from '@angular/common/locales/fr';

// Firebase
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';

// Angular Material
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';

// Pages
import { AppComponent } from './app.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { SponsorsPageComponent } from './pages/sponsors-page/sponsors-page.component';
import { TeamsComponent } from './pages/teams/teams.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

// Components
import { ChampionshipResultComponent } from './components/championship-result/championship-result.component';
import { ChampionshipRankingComponent } from './components/championship-ranking/championship-ranking.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SponsorImageComponent } from './components/sponsor-image/sponsor-image.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { HistoryPageComponent } from './pages/history-page/history-page.component';
import { ReglementInterieurPageComponent } from './pages/reglement-interieur-page/reglement-interieur-page.component';
import { PresentationPageComponent } from './pages/presentation-page/presentation-page.component';
import { registerLocaleData } from '@angular/common';
import { NewsPageComponent } from './pages/news-page/news-page.component';
import { TournamentPageComponent } from './pages/tournament-page/tournament-page.component';
import { TeamCardComponent } from './components/team-card/team-card.component';
import { ContentPageComponent } from './pages/content-page/content-page.component';

registerLocaleData(localeFr);

const angularMaterialsModule = [
  MatCardModule,
  MatSelectModule,
  MatMenuModule,
  MatButtonModule,
  MatDialogModule,
  MatTableModule,
  MatPaginatorModule,
  MatIconModule,
  MatFormFieldModule,
  MatTabsModule
]

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    NotFoundComponent,
    ChampionshipResultComponent,
    ChampionshipRankingComponent,
    SponsorsPageComponent,
    SponsorImageComponent,
    HistoryPageComponent,
    ReglementInterieurPageComponent,
    PresentationPageComponent,
    TeamsComponent,
    NewsPageComponent,
    TournamentPageComponent,
    TeamCardComponent,
    ContentPageComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    CoreModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    ...angularMaterialsModule
  ],
  exports: [
    CoreModule,
    ...angularMaterialsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
