<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="false">
        <mat-toolbar>Menu</mat-toolbar>
        <mat-nav-list>
            <a mat-list-item routerLink="/" (click)="drawer.close()">Home</a>
            <button mat-button [matMenuTriggerFor]="admin" aria-label="Administration" *ngIf="(auth.authState | async)">
                Administration
            </button>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
        <mat-toolbar>
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
                *ngIf="isHandset$ | async">
                <mat-icon aria-label="Side nav toogle icon">menu</mat-icon>
            </button>
            <span class="logo" routerLink=".">⚽️ Challenge Celtic</span>
            <span class="fill-space"></span>
            <div *ngIf="!(isHandset$ | async)">
                <a mat-button routerLink="/" [queryParams]="{'division': '1'}"
                    routerLinkActive="active">Championnat</a>
                <a mat-button routerLink="/tournoi">Championnat +50</a>
                <a mat-button routerLink="/partenaires" routerLinkActive="active">Partenaires</a>
                <a mat-button routerLink="/clubs" routerLinkActive="active"><span>Les équipes</span></a>
                <a mat-button routerLink="/actualites">Actualités</a>
                <a mat-button href="https://www.facebook.com/challengeceltic" target="_blank">
                    <mat-icon aria-hidden="false" aria-label="Facebook">facebook</mat-icon>&nbsp;
                    Facebook
                </a>
                <a mat-button routerLink="/admin" *ngIf="(auth.authState | async)">Administration</a>
            </div>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #admin="matMenu">
                <a mat-menu-item routerLink="/admin/clubs">
                    <span>Clubs</span>
                </a>
                <a mat-menu-item routerLink="/admin/equipes">
                    <span>Équipes</span>
                </a>
                <hr />
                <a mat-menu-item routerLink="/admin/saisons">
                    <span>Saisons</span>
                </a>
                <a mat-menu-item routerLink="/admin/matchs">
                    <span>Matchs</span>
                </a>
                <hr />
                <a mat-menu-item routerLink="/admin/actualites">
                    <span>Actualités</span>
                </a>
            </mat-menu>

            <mat-menu #menu="matMenu">
                <a mat-menu-item routerLink="historique">
                    <span>Historique</span>
                </a>
                <a mat-menu-item routerLink="presentation">
                    <span>Présentation</span>
                </a>
                <a mat-menu-item routerLink="palmares">
                    <span>Palmares</span>
                </a>
                <a mat-menu-item routerLink="reglement-interieur">
                    <span>Règlement intérieur</span>
                </a>
                <a mat-menu-item routerLink="statuts">
                    <span>Statuts</span>
                </a>
                <a mat-menu-item routerLink="bureau-du-challenge-celtic">
                    <span>Bureau du Challenge Celtic</span>
                </a>
                <a mat-menu-item routerLink="/user/login">
                    <span>Connexion</span>
                </a>
                <!-- <a mat-menu-item routerLink="/login" *ngIf="!(auth.authState | async)">
                    <span>Connexion</span>
                </a> -->
                <!-- <a mat-menu-item routerLink="" *ngIf="(auth.authState | async)" (click)="logout()">
                    <span>Déconnexion</span>
                </a> -->
            </mat-menu>
        </mat-toolbar>
        <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>