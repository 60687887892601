<div class="snackbar-container">
    <div class="snackbar-content">
        <mat-icon *ngIf="type == 'info'" class="alert-success">check_circle_outline</mat-icon>
        <mat-icon *ngIf="type == 'danger'" class="alert-danger">error_outline</mat-icon>
        <span>{{ message }}</span>
    </div>
    <button (click)="onDismiss()" mat-icon-button class="snackbar-action">
        <mat-icon>close</mat-icon>
    </button>
</div>
