import { ChoiceOption } from "../choice-option/choice-option";

export class ChoiceList {
    private choices: ChoiceOption[] = [
        { table: 'championship_game', field: 'state', value: 'scheduled', label: 'Planifié' },
        { table: 'championship_game', field: 'state', value: 'completed', label: 'Joué' },
        { table: 'championship_game', field: 'state', value: 'withdrawn', label: 'Forfait' },
        { table: 'championship_game', field: 'state', value: 'cancelled', label: 'Annulé' },
        { table: 'championship_game', field: 'state', value: 'postponed', label: 'Reporté' },
        { table: 'championship_day', field: 'state', value: 'draft', label: 'Brouillon' },
        { table: 'championship_day', field: 'state', value: 'scheduled', label: 'Planifié' },
        { table: 'championship_day', field: 'state', value: 'completed', label: 'Joué' },
        { table: 'championship_day', field: 'exempted_team_referee', value: 'yes', label: 'Oui' },
        { table: 'championship_day', field: 'exempted_team_referee', value: 'no', label: 'Non' },
        { table: 'championship_game', field: 'division', value: '1', label: 'Division 1' },
        { table: 'championship_game', field: 'division', value: '2', label: 'Division 2' },
        { table: 'championship_season', field: 'division', value: '1', label: 'Division 1' },
        { table: 'championship_season', field: 'division', value: '2', label: 'Division 2' },
        { table: 'championship_game', field: 'withdrawn_team', value: 'host', label: 'Équipe à domicile' },
        { table: 'championship_game', field: 'withdrawn_team', value: 'guest', label: 'Équipe en déplacement' },
        { table: 'article', field: 'state', value: 'draft', label: 'Brouillon', order: 1 },
        { table: 'article', field: 'state', value: 'published', label: 'Publié', order: 2 },
        { table: 'article', field: 'state', value: 'retired', label: 'Archivé', order: 3 },
        { table: 'article', field: 'category', value: 'tournament', label: 'Tournoi +50', order: 1 },
        { table: 'article', field: 'category', value: 'meeting', label: 'Réunion', order: 1 },
        { table: 'article', field: 'category', value: 'news', label: 'Actualité', order: 2 },
        { table: 'sys_ui_page', field: 'path', value: '/historique', label: 'Historique'},
        { table: 'sys_ui_page', field: 'path', value: '/presentation', label: 'Présentation' },
        { table: 'sys_ui_page', field: 'path', value: '/palmares', label: 'Palmares' },
        { table: 'sys_ui_page', field: 'path', value: '/reglement-interieur', label: 'Réglement interieur' },
        { table: 'sys_ui_page', field: 'path', value: '/bureau-du-challenge-celtic', label: 'Bureau du Challenge Celtic' },
        { table: 'sys_ui_page', field: 'path', value: '/statuts', label: 'Statuts' },
        { table: 'user', field: 'roles', value: 'admin', label: 'Admin' },
        { table: 'user', field: 'roles', value: 'team_manager', label: 'Entraineur' },
        { table: 'user', field: 'roles', value: 'team_player', label: 'Joueur' },
    ]

    getChoice(table: string, field: string, value: string) {
        return this.choices.find(choice => choice.table == table && choice.field == field && choice.value == value);
    }

    getChoices(table: string, field: string) {
        return this.choices.filter(choice => choice.table == table && choice.field == field).sort((a, b) => {
            if(!a.order || !b.order){
                return this._sortBy(a.label, b.label)
            }

            return this._sortBy(a.order, b.order);
            
        });
    }

    _sortBy(x: any, y: any){
        if(x < y){
            return -1;
        }

        if(x > y){
            return 1;
        }

        return 0;
    }
}
