import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { createClient, Entry } from 'contentful';

import * as marked from 'marked';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {

  private cdaClient = createClient({
    space: environment.contenful.space,
    accessToken: environment.contenful.accessToken
  });

  // convert markdown string to 
  markdownToHtml(md: string) {
    return marked(md);
  }

  getContentCollection(contentType: string, query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: contentType
    }, query)).then(result => result.items
    );
  }

  getContent(contentType, query: object): Promise<any> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: contentType
    }, query)).then(
      result => {
        if (!result) {
          return null;
        }

        if (result.items.length > 0 && result.items[0]) {
          return result.items[0];
        }
      }
    )
  }

  getPageContent(title: string): Promise<any> {
    return this.getContent('pageContent', {
      'fields.slug': title
    }).then(
      (item: Entry<any>) => {
        if (item) {
          return { ...item.fields }
        }

        return null;
      }
    ).catch(exception => {
      console.warn(exception, 'getPageContent');

      throw exception;
    })
  }
}
