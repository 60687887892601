<mat-card>
    <h2>Résultats</h2>
    <hr />
    <div class="text-center">
        <div *ngIf="loading">
            Chargement...
        </div>
        <div *ngIf="!loading && games.length == 0" class="text-center">
            <span class="text-muted">Aucun match</span>
        </div>
        <table *ngIf="!loading && games.length > 0">
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor let-game [ngForOf]="games">
                    <tr>
                        <td width="40%" class="text-center">{{ game.host }}
                            <span *ngIf="game.state == 'Forfait' && game.withdrawn_team == 'host'">&nbsp;(forfait)</span>
                        </td>
                        <td class="text-center">
                            <div>
                                <span *ngIf="game.state == 'Planifié'">-</span>
                                <span *ngIf="game.state == 'Reporté'">Reporté</span>
                                <span *ngIf="game.state == 'Joué' || game.state == 'Forfait'">{{ game.host_goals }} -
                                    {{ game.guest_goals }}</span>
                            </div>
                        </td>
                        <td width="40%" class="text-center">
                            {{ game.guest }}
                            <span *ngIf="game.state == 'Forfait' && game.withdrawn_team == 'guest'">&nbsp;(forfait)</span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" class="details text-center text-muted">{{ game.scheduledOn | date }}<span *ngIf="game.address"> à {{ game.address }}</span></td>
                    </tr>
                </ng-template>
            </tbody>
            <tfoot *ngIf="(exemptedTeam|async)">
                <tr>
                    <td colspan="3">Équipe exempte : {{ (exemptedTeam|async)!.name }}</td>
                </tr>
            </tfoot>
        </table>
    </div>
</mat-card>