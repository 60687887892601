export class FootballTeam{
    id: string;
    name: string;
    club: string;
    championship: string;
    points?: number;
    played?: number;
    won?: number;
    drawn?: number;
    lost?: number;
    conceded_goals?: number;
    scored_goals?: number;
    referee?: number;

    constructor(){
        this.points = 0;
        this.played = 0;
        this.won = 0;
        this.drawn = 0;
        this.lost = 0;
        this.referee = 0;
        this.conceded_goals = 0;
        this.scored_goals = 0;
    }
}