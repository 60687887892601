import { Component, OnInit, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormAction } from '../../models/form-action/form-action';
import { Record } from '../../models/record';

@Component({
  selector: 'app-form-actions',
  templateUrl: './form-actions.component.html',
  styleUrls: ['./form-actions.component.scss']
})

export class FormActionsComponent implements OnInit {
  @Input() record: Record;
  @Input() actions: FormAction[];

  primaryActions: FormAction[];
  secondaryActions: FormAction[];

  constructor(private location: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    if(this.actions){
      this.primaryActions = this.actions.filter(action => action.primary && this.isVisible(action));
      this.secondaryActions = this.actions.filter(action => !action.primary && this.isVisible(action));
    }
  }

  onClick(action: FormAction) {
    try {
      if(action.type === 'script'){
        action.onClick();
      }

      if(action.type === 'link'){
        this.location.navigate([action.link], {queryParams: action.queryParams});
      }
    } catch (exception) {
      console.warn(exception);
    }
  }

  isVisible(action: FormAction): boolean {
    try {
      if (action.isVisible === undefined) {
        return true;
      }

      if (action.isVisible instanceof Function) {
        const isVisible = action.isVisible as Function;
        return isVisible(this.record);
      }

      return action.isVisible as boolean;
    } catch (exception) {
      return true;
    }
  }

  visible(actions: FormAction[]): FormAction[] {
    return actions.filter(action => this.isVisible(action));
  }
}
