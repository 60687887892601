export class AccessControl {
    create: boolean;
    read: boolean;
    update: boolean;
    delete: boolean;

    constructor(){
        this.create = true;
        this.read = true;
        this.update = true;
        this.delete = true;
    }
}
