import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Attachment } from '../../models/attachment/attachment';
import { FirebaseManager } from '../firebase/firebase-manager.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { Record } from '../../models/record';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';
import { updateLocale } from 'moment';

@Injectable({
  providedIn: 'root'
})

export class FileStorageService {

  constructor(private http: HttpClient, private firebase: FirebaseManager, private firestore: AngularFireStorage) {

  }

  getFile(table: string, id: string, field: string): Promise<Record> {
    return this.firebase.getRecord('sys_file',
      query => query.where('table', '==', table).where('target_id', '==', id).where('field', '==', field)
    )
  }

  getAttachments(table: string, id: string): Promise<Attachment[]> {
    return this.firebase.getCollection<Attachment>('sys_attachment',
      query => query.where('table', '==', table).where('target_id', '==', 'id')).then(
        (files: any[]) => files.map(file => {
          let attachment = new Attachment();
          attachment.id = file.id;
          attachment.name = file.name;
          attachment.content_type = file.content_type;
          attachment.object_id = file.object_id;
          attachment.file = environment.baseUrl + file.file;
          attachment.format = file.format;

          return attachment;
        })
      ).catch(error => {
        throw this.handleError(error)
      });
  }

  rename(attachment: Attachment): Promise<any> {
    return this.http.put(`${environment.baseUrl}file/api/`, {
      id: attachment.id,
      name: attachment.name,
      content_type: attachment.content_type,
      object_id: attachment.object_id
    }).pipe(
      catchError(error => this.handleError(error))
    ).toPromise();
  }

  upload(file: File, table: string, id: string, field: string): Observable<UploadTaskSnapshot> {
    if (!table || !id) {
      throw 'Missing parameters';
    }

    const _formData = new FormData();
    _formData.append('file', file, file.name);
    _formData.append('object_id', id);
    _formData.append('content_type', table);

    const filePath = `${table}/${id}/${field}/${file.name}`;
    const uploadTask = this.firestore.upload(filePath, file);

    return uploadTask.snapshotChanges();
  }

  attach(id: string, table: string, target: string) {
    // return this.firestore.upload('sys_attachment').add()
    // return this.http.put(`${environment.baseUrl}/file/api`, {
    //   id: id,
    //   content_type: table,
    //   object_id: target
    // }).toPromise();
  }

  delete(path: string): Observable<any> {
    return this.firestore.refFromURL(path).delete();
  }

  private handleError(response: HttpErrorResponse): string {
    try {
      let error = 'Une erreur est survenue.';
      const status = response.status;

      switch (status) {
        case 405:
          error = 'Vous n\êtes pas authorisé à effectuer cette action.';
          break;
        default:
          error = 'Une erreur inattendue est survenue. Merci de réessayer.';
          break;
      }

      return error;
    } catch (exception) {
      return 'Une erreur inattendue est survenue. Merci de réessayer.';
    }
  }
}
