import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Record } from 'src/app/modules/core/models/record';
import { FirebaseManager } from 'src/app/modules/core/services/firebase/firebase-manager.service';
import { UINotificationService } from 'src/app/modules/core/services/ui-notification/ui-notification.service';

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.scss']
})
export class ContentPageComponent implements OnInit {
  page: Record;
  isLoggedIn: Observable<any>;

  constructor(private firebase: FirebaseManager,
     private auth: AngularFireAuth,
      private location: Router,
      private notification: UINotificationService) { }

  ngOnInit(): void {
    this.isLoggedIn = this.auth.authState;
    var path = this.location.url;

    if(path){
      this.firebase.getRecord('sys_ui_page', query => query.where('path', '==', path)).then(
        page => {
          if(!page){
            this.notification.snack('Aucune page de contenu trouvé', 'danger');
            return;
          }

          this.page = page
        }
      ).catch(error => this.notification.snack(error, 'danger'));
    }
  }

}
