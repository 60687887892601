import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges, ɵChangeDetectorStatus } from '@angular/core';
import { Attachment } from '../../models/attachment/attachment';
import { FileStorageService } from '../../services/file-storage/file-storage.service';
import { FormSection } from '../../models/form-section/form-section';
import { FormAction } from '../../models/form-action/form-action';
import { FormButton } from '../../models/form-button/form-button';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessControl } from '../../models/access-control/access-control';
import { FirebaseManager } from '../../services/firebase/firebase-manager.service';
import { MatDialog } from '@angular/material/dialog';
import { Record } from '../../models/record';


// @param: List of fields (comma-separated)
export class FormOptions {
  page?: string;
  redirectTo?: string;
  previousPage?: string;
  title: {
    hidden?: boolean;
    default: string;
    prefix?: string;
    fields: string;
  };

  permissions?: AccessControl;

  constructor() {
    this.permissions = new AccessControl();
  }
}

@Component({
  selector: 'app-form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.scss']
})

export class FormCardComponent implements OnInit, OnChanges {
  @Input() sections: FormSection[];
  @Input() record: Record;
  @Input() actions: FormAction[];
  @Input() buttons: FormButton[];
  @Input() loading: boolean;
  @Input() options: FormOptions;

  nextRecord: Record;
  previousRecord: Record;

  _options: FormOptions = {
    title: {
      hidden: false,
      default: 'Nouvel enregistrement',
      fields: 'short_description'
    },
    permissions: {
      read: true,
      create: true,
      update: true,
      delete: true
    }
  };

  title: string;
  attachments: Attachment[] = [];
  files: File[] = [];
  showUploader = false;
  lastUpdate: Date;
  redirectTo: string;
  orderBy: string;
  orderByDir: 'asc' | 'desc';

  constructor(private dialog: MatDialog,
    private firebase: FirebaseManager,
    private attachment: FileStorageService,
    private location: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.location.queryParams.subscribe(parameters => {
      if (parameters['sysparm_redirect']) {
        this.redirectTo = parameters['sysparm_redirect'];
      }

      if(parameters['sysparm_sort']){
        this.orderBy = parameters['sysparm_sort'];

        if(parameters['sysparm_sort_dir']){
          this.orderByDir = parameters['sysparm_sort_dir'];
        }
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      this._options = { ...this._options, ...this.options };

      if (!this._options.permissions) {
        this._options.permissions = new AccessControl();
      }

      if (changes.record) {
        this.setTitle();

        if (!this.record?.sys_id || !this.record?.sys_table) {
          return;
        }

        this.lastUpdate = this.record.data.sys_updated_on;
        
        this.attachment.getAttachments(this.record.sys_table, this.record.sys_id).then(
          attachments => this.record.attachments = attachments
        ).catch(error => console.warn(error))

        this.firebase.getPreviousRecord(this.record.sys_table, this.record.sys_id, this.orderBy, this.orderByDir).then(
          record => this.previousRecord = record
        );
        
        this.firebase.getNextRecord(this.record.sys_table, this.record.sys_id, this.orderBy, this.orderByDir).then(
          record => this.nextRecord = record
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }

  goToRecord(record: Record): void{
    this.router.navigate([`/admin/${record.sys_table}/edit/${record.sys_id}`], {
      queryParams: {
        sysparm_sort: this.orderBy,
        sysparm_sort_dir: this.orderByDir
      }
    });
  }

  onRedirectTo() {
    this.router.navigateByUrl(this.redirectTo);
  }

  isVisible(button: FormButton): boolean {
    if (!button.isVisible) {
      return true;
    }

    if (button.isVisible instanceof Function) {
      return button.isVisible();
    }

    return button.isVisible;
  }

  onClick(button: FormButton) {
    try {
      button.onClick(this.dialog, this.record);
    } catch (exception) {
      console.warn(exception);
    }
  }

  openAttachmentsWindow() {
    // const dialog = this.dialog.open(FormAttachmentsComponent, {
    //   context: {
    //     table: this.record.table,
    //     id: this.record.id,
    //   },
    //   closeOnBackdropClick: true,
    // });

    // dialog.onClose.subscribe(() => {
    //   this.attachment.getAttachments(this.record.table, this.record.id).then(
    //     attachments => this.record.attachments = attachments
    //   ).catch(error => console.warn(error))
    // })
  }

  refresh(record) {
    this.lastUpdate = record.data.sys_updated_on?.toDate();
    this.setTitle();
  }

  setTitle() {
    try {
      const options = this._options?.title;

      if (!this.record || !this.record.sys_id) {
        this.title = options.default;
        return;
      }

      if (options.fields) {
        this.title = options.fields.split(',').reduce((title, field) => {
          if (this.record.data[field]) {
            title += this.record.data[field] + ' ';
          }

          return title;
        }, '').trim();
      }

      if (options.prefix) {
        this.title = `${options.prefix} - ${this.title}`;
      }
    } catch (exception) {
      console.warn(exception);
    }
  }
}
