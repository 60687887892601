<div class="container">
    <div class="row">
        <div class="col">
            <h1>Actualités du Challenge Celtic</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12" *ngFor="let post of posts">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{ post.data.short_description }}</mat-card-title>
                    <mat-card-subtitle>Date de publication : {{ post.data.sys_created_on | date }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <div [innerHTML]="post.data.content"></div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
