import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private returnUrl: string;

  constructor(private auth: AngularFireAuth, private location: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    
      return this.auth.authState.pipe(map(user => {
        if (user !== null) {
          return true;
        } else {
          this.location.navigate(['user/login'], { queryParams: { returnUrl: state.url } });
          return false;
        }
    }));
  }

  //   return this.auth.currentUser.then(user => {
  //     if (!user) {
  //       this.location.navigate(['user/login'], { queryParams: { returnUrl: state.url } });
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   });
  // }
}
