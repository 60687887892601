<div class="container">
    <div class="row">
        <div class="col">
            <h1>Clubs du Challenge Celtic</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4" *ngFor="let club of clubs">
            <app-team-card [id]="club.sys_id"></app-team-card>
        </div>
    </div>
</div>