import { Component, OnInit, Input, ViewChild, OnChanges, ChangeDetectorRef, SimpleChanges, ViewEncapsulation, HostListener } from '@angular/core';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ChoiceList } from '../../models/choice-list/choice-list';
import { TableColumn } from '../../models/table-column/table-column';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject } from 'rxjs';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { FirebaseManager } from '../../services/firebase/firebase-manager.service';
import { MatDialog } from '@angular/material/dialog';
import { UINotificationService } from '../../services/ui-notification/ui-notification.service';
import { Record } from '../../models/record';

export class TableCondition {
  field: {
    name: string;
    label: string;
    type: string;
  };
  operator: {
    value: string;
    label: string;
  };
  value: any;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TableComponent implements OnInit, OnChanges {
  @Input() records: Record[];
  @Input() columns: TableColumn[];
  @Input() redirectTo: string;
  @Input() keywords: string;
  @Input() conditions: TableCondition[];
  @Input() sortBy: { field: string, direction: 'asc' | 'desc' };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  initialized: boolean;
  rows: MatTableDataSource<any>;
  loading: boolean;
  headers: string[];
  keyEvent: BehaviorSubject<Event> = new BehaviorSubject(null);
  edit: any;
  progress: number;
  initialSelection: [];
  allowMultiSelect: true;
  selection = new SelectionModel<any>(true, []);
  canEdit: boolean

  @HostListener('document:keydown', ['$event'])
  handleKeydownEvent(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      this.deleteSelection();
      document.dispatchEvent(new Event('keyup'));
    }
  }

  constructor(private firebase: FirebaseManager,
    private snack: UINotificationService,
    private location: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog) {
  }

  ngOnInit() {
    this.loadTableColumns().then(
      () => this.initilizeDatasource()
    );


    // this.db.events.subscribe(event => {
    //   console.log(event);
    //   let row = this.rows?.data.find(record => record.id == event.record.id);

    //   if(row){
    //     this.columns.forEach((column: TableColumn) => {
    //       if(column['choices']){
    //         const choice = column['choices'].find(choice => choice.value == event.record[column.name]);

    //         if(choice){
    //           row[column.name] =  choice.label;
    //         }
    //       }else{
    //         row[column.name] = event.record[column.name];
    //       }
    //     })
    //   }
    // })
  }

  async loadTableColumns() {
    this.initialized = false;

    this.headers = this.columns.map(column => column.name);

    if (!this.headers.find(header => header == 'select')) {
      this.headers.splice(0, 0, 'select');
    }

    if (!this.headers.find(header => header == 'actions')) {
      this.headers.splice(this.headers.length, 0, 'actions');
    }

    this.columns = await Promise.all(this.columns.map(async column => {
      if (column.type == 'reference') {
        column.choices = await this.firebase.getCollection(column.reference).then(
          records => records.map(record => {
            const columns = column.displayValue.split(',');
            const label = columns.reduce((output, column) => {
              if (record.data[column]) {
                output += ' ' + record.data[column];
              }

              return output;
            }, '');

            return { table: record.sys_table, field: column.name, value: record.sys_id, label: label }
          })
        ).catch(error => {
          console.error(error);
          throw error;
        })
      }

      if (column.type == 'choice') {
        column.choices = column.choices || [];
      }

      return column;
    }))

    this.initialized = true;
  }

  onRowClick(element: Record) {
    const url = this.location.snapshot.url.reduce((path: string, segment: UrlSegment) => {
      return path + '/' + segment.path;
    }, '');

    this.router.navigate([element.link], {
      queryParams: {
        sysparm_redirect: `${url}?sysparm_index=${this.paginator.pageIndex}`,
        sysparm_sort: this.sortBy.field,
        sysparm_sort_dir: this.sortBy.direction
      }
    });
  }

  ngOnChanges(change: SimpleChanges) {

    if (change['columns']) {
      this.loadTableColumns();
    }

    if (change['records']) {
      this.initilizeDatasource();
    }

    if (change['keywords'] && this.rows) {
      this.rows.filter = this.keywords?.trim().toLowerCase();
    }

    if (change['sortBy'] && this.rows) {
      this.sortRows(this.sortBy.field, this.sortBy.direction)
    }
  }

  intializePaginator(pageIndex: number) {
    this.paginator.pageIndex = pageIndex || 0;
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      return `${(page * pageSize) + 1} - ${((page + 1) * pageSize)} sur ${length}`;
    }

    this.paginator._intl.itemsPerPageLabel = "Item(s) par page";
    this.paginator._intl.previousPageLabel = "Précédent";
    this.paginator._intl.nextPageLabel = "Suivant";
    this.paginator._intl.firstPageLabel = "Aller à la première page";
    this.paginator._intl.lastPageLabel = "Aller à la dernière page";

    this.paginator.page.subscribe(() => {
      localStorage.setItem('paginator.pageIndex', this.paginator.pageIndex.toString());
      this.selection.clear()
    })
  }

  initilizeDatasource() {
    if (!this.initialized) {
      return;
    }

    this.loading = true;

    if (!this.records) {
      this.loading = false;
      return;
    }

    let counter = 0;
    this.progress = 0;

    const data = this.records.map((record: Record) => {
      const cells = this.getRowData(this.columns, record);

      return Promise.all(cells).then(cells => {
        return cells[0];
      }).then((record) => {
        counter++;
        this.progress = Math.round(counter / this.records.length * 100) / 100;
        return record;
      }).catch(error => {
        throw new Error(error);
      }).finally(() => this.loading = false);
    });

    Promise.all(data).then(result => {
      result = result.map(row => {
        return {
          sys_id: row.sys_id,
          sys_table: row.sys_table,
          link: row.link,
          ...row.data
        }
      });

      this.location.queryParams.subscribe(params => {
        if (params['sysparm_index']) {
          this.intializePaginator(parseInt(params['sysparm_index']));
        }
      })

      this.rows = new MatTableDataSource(result);
      this.rows.filter = this.keywords?.trim().toLowerCase();
      // this.rows.filterPredicate = (element: Element, value: string): boolean => {
      //   const data = element['data'];

      //   return Object.keys(data).reduce((match: boolean, key: string) => {
      //     if(match){
      //       return true;
      //     }
          
      //     if(data[key]){
      //         match = data[key].toString().toLowerCase().indexOf(value) > -1;
      //     }

      //     return match;
      //   }, false);
      // }

      this.rows.paginator = this.paginator;

      this.rows.sort = this.sort;
    }).catch(
      error => console.warn(error)
    ).finally(() => {
      this.loading = false;
    });
  }

  getRowCell(column: TableColumn, record: Record): Promise<string> {
    try {
      switch (column.type) {
        case 'reference':
          if (!record.data[column.name]) {
            return new Promise((resolve, reject) => resolve(record.data[column.name]));
          }

          if (!column.choices) {
            column.choices = [];
            console.warn(`No choices defined for column ${column.name}`)
            return new Promise((resolve, reject) => resolve(record.data[column.name]));
          }

          const choice = column?.choices.find(choice => choice.value == record.data[column.name]);

          if (!choice) {
            return new Promise((resolve, reject) => resolve(record.data[column.name]));
          }

          return new Promise((resolve, reject) => resolve(choice.label));
        case 'choice':
          return new Promise((resolve, reject) => {
            if (!record.data[column.name]) {
              resolve(record.data[column.name]);
            }

            const choice = new ChoiceList().getChoice(record.sys_table, column.name, record.data[column.name]);

            if (!choice) {
              reject(record[column.name]);
            }

            resolve(choice.label);
          });
        default:
          return new Promise((resolve, reject) => resolve(record.data[column.name]));
      }
    } catch (exception) {
      throw new Error(exception);
    }
  }

  getRowData(columns: TableColumn[], record: Record): Promise<Record>[] {
    return columns.map((column: TableColumn) => {

      return this.getRowCell(column, record).then(
        data => {
          record.data[column.name] = data;
          return record;
        }
      ).catch(exception => {
        throw new Error(exception);
      });
    });
  }

  sumRows(column: TableColumn): number {
    return this.selection.selected.reduce((total, record) => {
      return total + record[column.name];
    }, 0);
  }

  sortRows(field: string, direction: 'asc' | 'desc') {
    const sortState: Sort = { active: field, direction: direction };
    this.sort.active = field;
    this.sort.direction = direction;

    const header = this.sort.sortables.get(sortState.active) as MatSortHeader;
    header._setAnimationTransitionState({ toState: 'active' });

    this.sort.sortChange.emit();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const rows = this.getVisibleRows();
    const numSelected = this.selection.selected.length;
    const numRows = rows?.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    const rows = this.getVisibleRows();

    this.isAllSelected() ?
      this.selection.clear() :
      rows.forEach(row => this.selection.select(row));
  }

  getVisibleRows() {
    const index = this.rows?.paginator.pageIndex;
    const size = this.rows?.paginator.pageSize;

    const startIndex = index * size;
    const endIndex = startIndex + size;

    return this.rows?.data.slice(startIndex, endIndex);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  deleteSelection() {
    if (this.selection.selected.length == 0) {
      return;
    }

    if (!confirm('Confirmer la suppression ?')) {
      return;
    }

    Promise.all(this.selection.selected.map(
      record => this.firebase.delete(record).then(
        () => this.rows.data = this.rows.data.filter(row => row.id != record.id)
      ))
    ).then(() => this.selection.clear()
    ).catch(error => {
      let message = error['detail'] || error['message'];

      if (!message) {
        message = "Erreur lors de la suppression.";
      }

      this.snack.snack(message, 'danger');
    }).finally(() => this.snack.snack('Élément(s) supprimée(s).'))
  }

  isEdited(element, column) {
    if (!this.edit) {
      return false;
    }

    if (this.edit.id == element.id && this.edit.field == column.name) {
      return true;
    }

    return false;
  }
}

//   save(id, field, option) {
//     let record = this.records.find(record => record.id == id);

//     if (!record) {
//       this.edit = null;
//       return;
//     }

//     let data = new Record(record.sys_table, record.sys_id);
//     data = { ...data };

//     if (option) {
//       data[field] = option.value;
//     } else {
//       data[field] = null;
//     }

//     this.db.save<any>(data).then(
//       result => {
//         let row = this.rows.data.find(record => record.id == result.id);
//         row[field] = option?.label || null;

//         this.edit = null;
//         this.snack.snack('Enregistré');
//       }
//     ).catch(
//       error => {
//         let message = error[field] || error['message'];

//         if (!message) {
//           message = "Une erreur est survenue";
//         }

//         this.snack.snack(message, 'danger');
//       }
//     )
//   }
// }