import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar-content',
  templateUrl: './snackbar-content.component.html',
  styleUrls: ['./snackbar-content.component.scss']
})
export class SnackbarContentComponent implements OnInit {
  message: string;
  type: 'info' | 'warning' | 'danger';

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private _snackBar: MatSnackBarRef<SnackbarContentComponent>) { 
    this.message = data.message;
    this.type = data.type;
  }

  ngOnInit(): void {
  }

  onDismiss(){
    this._snackBar.dismiss();
  }

}
